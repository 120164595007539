<ion-content [ngClass]="util.isIOS() ? 'safe_area' : '' ">

  <div
    class="service_thumb"
    [ngStyle]="{ 'background-image': 'url('+thumb+')', 'background-position': 'center', 'background-size': 'cover' }">

    <div style="text-align: left; display: flex; align-items: center;">
      <div *ngIf="user?.type == 'Designer' && user?.id == service?.Services.designer_id" class="square_button" (click)="presentEditServicePage()">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.25 22.9393C3.25 22.387 3.69772 21.9393 4.25 21.9393H20.25C20.8023 21.9393 21.25 22.387 21.25 22.9393V22.9393C21.25 23.4916 20.8023 23.9393 20.25 23.9393H4.25C3.69772 23.9393 3.25 23.4916 3.25 22.9393V22.9393Z" fill="#6529F7"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.9571 4.06059C16.5666 3.67007 15.9334 3.67007 15.5429 4.06059L14.6642 4.93924L18.25 8.52503L19.1287 7.64638C19.5192 7.25585 19.5192 6.62269 19.1287 6.23217L16.9571 4.06059ZM7.16425 12.4392L13.25 6.35345L16.8358 9.93924L10.75 16.025L7.16425 12.4392ZM5.75003 13.8535L5.25 14.3535V17.9393H8.83579L9.33582 17.4392L5.75003 13.8535ZM14.1287 2.64638C15.3003 1.47481 17.1997 1.47481 18.3713 2.64638L20.5429 4.81795C21.7145 5.98953 21.7145 7.88902 20.5429 9.06059L10.25 19.3535C9.87493 19.7286 9.36622 19.9393 8.83579 19.9393H5.25C4.14543 19.9393 3.25 19.0438 3.25 17.9393V14.3535C3.25 13.8231 3.46071 13.3143 3.83579 12.9393L14.1287 2.64638Z" fill="#6529F7"/></svg>
      </div>
      <div class="square_button" (click)="util.share_link('/me/service/'+service?.Services.id, 'service')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.0014 10.0398C14.972 10.5913 15.3953 11.0622 15.9468 11.0916C16.6995 11.1318 17.2956 11.1808 17.7615 11.23C18.3871 11.2959 18.7672 11.6802 18.8304 12.233C18.9211 13.0267 19 14.2277 19 15.9999C19 17.7722 18.9211 18.9732 18.8304 19.7669C18.7671 20.3206 18.3879 20.7038 17.7632 20.7697C16.6692 20.8852 14.8608 20.9999 12 20.9999C9.13925 20.9999 7.33078 20.8852 6.23675 20.7697C5.61207 20.7038 5.23289 20.3206 5.16959 19.7669C5.07886 18.9732 5 17.7722 5 15.9999C5 14.2277 5.07886 13.0267 5.16959 12.233C5.23278 11.6802 5.61292 11.2959 6.23854 11.23C6.7044 11.1808 7.30055 11.1318 8.05324 11.0916C8.60474 11.0622 9.02798 10.5913 8.99858 10.0398C8.96918 9.48831 8.49827 9.06506 7.94677 9.09446C7.16393 9.13619 6.53306 9.18781 6.02877 9.24099C4.54436 9.39755 3.36032 10.4506 3.18253 12.0059C3.0812 12.8922 3 14.1715 3 15.9999C3 17.8284 3.0812 19.1077 3.18253 19.994C3.36021 21.5483 4.54151 22.6019 6.02684 22.7587C7.20806 22.8834 9.08921 22.9999 12 22.9999C14.9108 22.9999 16.7919 22.8834 17.9732 22.7587C19.4585 22.6019 20.6398 21.5483 20.8175 19.994C20.9188 19.1077 21 17.8284 21 15.9999C21 14.1715 20.9188 12.8922 20.8175 12.0059C20.6397 10.4506 19.4556 9.39755 17.9712 9.24099C17.4669 9.18781 16.8361 9.13619 16.0532 9.09446C15.5017 9.06506 15.0308 9.48831 15.0014 10.0398Z" fill="#6510FB"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.7929 6.20711C15.1834 6.59763 15.8166 6.59763 16.2071 6.20711C16.5976 5.81658 16.5976 5.18342 16.2071 4.79289L12.7071 1.29289C12.3166 0.902369 11.6834 0.902369 11.2929 1.29289L7.79289 4.79289C7.40237 5.18342 7.40237 5.81658 7.79289 6.20711C8.18342 6.59763 8.81658 6.59763 9.20711 6.20711L11 4.41421V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V4.41421L14.7929 6.20711Z" fill="#6510FB"/></svg>
      </div>
    </div>

  </div>

  <div style="margin-left: 20px; margin-top: 200px;">

    <div>
      <p class="service_title">{{util.decode(service?.Services.title)}}</p>
      
      <p class="service_description">{{util.decode(service?.Services.description)}}</p>

      <!-- <div *ngIf="user?.type == 'Designer' && user?.id == service?.Services.designer_id" class="service_activate_div">
        <div style="display: flex; justify-content: space-between;align-items: center;">
          <p><b>تفعيل الخدمة</b></p>
          <div class="skill_checkbox">
            <ion-toggle [checked]="service_status" color="tertiary"></ion-toggle>
          </div>
        </div>
        <p>قم بتعطيل الخدمة في حال عدم توفرها</p>
      </div> -->
    </div>

  </div>

  <h4>التسليمات
    <ion-icon id="deliverables-click-trigger" name="information-circle-outline"></ion-icon>
  </h4>
  <ion-popover trigger="deliverables-click-trigger" triggerAction="click">
    <ng-template>
      <p>الملفات و الأشياء التي سوف يتم تسليمها للعميل</p>
    </ng-template>
  </ion-popover>
  <div class="service_deliverables">
    <div *ngFor="let d of deliverables" class="deliverable_item">
      <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.75 5.93933L5.25 10.4393L14.25 1.43933" stroke="#6510FB" stroke-width="2"/></svg>
      <p>{{util.decode(d)}}</p>
    </div>
  </div>

  <h4>مدة التسليم</h4>
  <div class="service_deliverables">
    <div class="primary_text">{{service?.ServicePackages[0].days}} أيام</div>
  </div>

  <ion-row>
    <ion-col>
      <h4>سعر الخدمة
        <ion-icon id="price-click-trigger" name="information-circle-outline"></ion-icon>
      </h4>
      <ion-popover trigger="price-click-trigger" triggerAction="click">
        <ng-template>
          <p>السعر المتفق عليه لهذه الخدمة</p>
        </ng-template>
      </ion-popover>

      <div class="primary_text">{{service?.ServicePackages[0].price}} ر.س</div>
    </ion-col>
    <ion-col>
      <h4>الدفعات
        <ion-icon id="batches-click-trigger" name="information-circle-outline"></ion-icon>
      </h4>
      <ion-popover trigger="batches-click-trigger" triggerAction="click">
        <ng-template>
          <p>عدد الدفعات التي يدفعها العميل ونسبة كل دفعة (٪)</p>
        </ng-template>
      </ion-popover>

      <span class="primary_text" *ngFor="let b of batches">{{b}}%&nbsp;</span>
    </ion-col>
  </ion-row>

  <h4>صور الخدمة</h4>
  <div *ngIf="service?.ServiceImages.length != 0" class="service_images_wrapper">
    <div *ngFor="let service_img of service?.ServiceImages; let ii = index" class="service_image">
      <div class="service_img" (click)="util.previewImage(api_path+'/'+service_img.File.thumb_url, '0', '1', 'preview')"
        [ngStyle]="{ 'background': 'url('+api_path+'/'+service_img.File.thumb_url+') center', 'background-size': 'cover' }">
      </div>
      <!-- <svg *ngIf="user?.type == 'Designer'" style="cursor: pointer;" (click)="promptRemoveServiceImage(ii)" xmlns="http://www.w3.org/2000/svg" class="ionicon rm_design_img" viewBox="0 0 512 512"><title>Trash</title><path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 112h352"/><path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg> -->
    </div>
    <!-- <div *ngIf="user?.type == 'Designer'" class="service_img" (click)="promptAddImageToService()">+</div> -->
  </div>
  <p *ngIf="service?.ServiceImages.length == 0">لا يوجد</p>

  
  <div *ngIf="service?.Services.designer_id != user?.id">
    <h4>المصمم</h4>
    <div class="user_wrapper" (click)="presentDesignerDetails(service?.Designer)">
      <img alt="user_img" class="user_img" [src]="api_path+'/'+service?.Designer?.File?.thumb_url" />
      <div>
        <p class="chat_user_name">{{util.decode(service?.Designer.User.display_name)}}</p>
      </div>
    </div>
  </div>

  <!-- <p class="request_detail_title">تصنيف الخدمة</p>
  <div class="request_detail_body in_settings">
    <p>{{skills_mapping[service?.Services.skill_id-1]['display']}}</p>
  </div> -->

  <!-- <h1>الباقةات</h1> -->
  <ion-spinner *ngIf="loadingPackages"></ion-spinner>

  <ion-row class="actions" *ngIf="user?.type == 'User'">
    <ion-col>
      <div class="action_button primary" (click)="makeNewRequestFromService()">اطلب الخدمة</div>
    </ion-col>
    <ion-col>
      <div class="action_button invert" (click)="chatWithDesigner()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 15.9013V18.9951L12.0622 16.0038L12.5913 15.9951C14.6952 15.9607 16.5886 15.7488 18.0044 15.5334C18.8979 15.3975 19.5187 14.7739 19.6536 13.9909C19.8406 12.9049 20 11.5289 20 10C20 8.47108 19.8406 7.09512 19.6536 6.00907C19.5187 5.22608 18.8979 4.60252 18.0044 4.46657C16.4559 4.23096 14.3383 4 12 4C9.66167 4 7.54408 4.23096 5.99562 4.46657C5.10214 4.60252 4.4813 5.22608 4.34643 6.00907C4.15936 7.09512 4 8.47108 4 10C4 11.5289 4.15936 12.9049 4.34643 13.9909C4.46355 14.6709 4.93854 15.2158 5.63108 15.4461L7 15.9013ZM2.37546 5.66957C2.66565 3.98488 4.00472 2.74648 5.69477 2.48932C7.31411 2.24293 9.53559 2 12 2C14.4644 2 16.6859 2.24293 18.3052 2.48932C19.9953 2.74648 21.3344 3.98488 21.6245 5.66957C21.8268 6.84372 22 8.33525 22 10C22 11.6647 21.8268 13.1563 21.6245 14.3304C21.3344 16.0151 19.9953 17.2535 18.3052 17.5107C16.8238 17.7361 14.8384 17.9586 12.6241 17.9949L6.50873 21.6085C5.84211 22.0024 5 21.5219 5 20.7476V17.344C3.64656 16.8939 2.62456 15.7766 2.37546 14.3304C2.17321 13.1563 2 11.6647 2 10C2 8.33525 2.17321 6.84372 2.37546 5.66957Z" fill="#6529F7"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7 7C6.44772 7 6 7.44772 6 8C6 8.55228 6.44772 9 7 9H17C17.5523 9 18 8.55228 18 8C18 7.44772 17.5523 7 17 7H7ZM7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44772 13 7 13H11C11.5523 13 12 12.5523 12 12C12 11.4477 11.5523 11 11 11H7Z" fill="#6529F7"/></svg>
        <span>محادثة</span>
      </div>
    </ion-col>
  </ion-row>

  <div style="display: block; height: 200px"></div>
  
</ion-content>

<loading></loading>
<modal></modal>