<ion-content [ngClass]="util.isIOS() ? 'safe_area' : '' ">

  <h1>طلب خدمة جديدة</h1>

  <div *ngIf="!request_submitted">

    <div *ngIf="service">
      <service-item
        type="client"
        width="full"
        [title]="util.decode(service?.Services.title)"
        [description]="util.decode(service?.Services.description)"
        [budget]="service?.ServicePackages[0].price"
        [d_name]="service?.Designer.User ? service?.Designer.User.display_name : ''"
        [d_img]="service?.Designer.File ? api_path+'/'+service?.Designer.File.thumb_url : ''"
        >
      </service-item>

      <p class="title">ملاحظات</p>
      <textarea rows="6" class="service_input" placeholder="ملاحظات لصاحب الخدمة ..." [(ngModel)]="service_description" required></textarea>
    </div>

    <div *ngIf="!service">

      <p class="title">العنوان</p>
      <input type="text" placeholder="تصميم موقع، استشارة، تطوير، ..." class="service_input" [(ngModel)]="title" required >

      <p class="title">مدة التسليم</p>
      <div class="service_submit_date_wrapper">
        <div [ngClass]="request_date_index == 0 ? 'active' : '' " (click)="setActiveDateIndex(0)" >اسبوع</div>
        <div [ngClass]="request_date_index == 1 ? 'active' : '' " (click)="setActiveDateIndex(1)" >شهر</div>
        <div [ngClass]="request_date_index == 2 ? 'active' : '' " (click)="setActiveDateIndex(2)" >مخصص</div>
      </div>

      <p class="title">ميزانية المشروع</p>
      <input type="number" class="service_input" [(ngModel)]="price">

      <p class="title">التفاصيل</p>
      <textarea rows="6" class="service_input" placeholder="اشرح الطلب بالتفصيل ..." [(ngModel)]="description" required></textarea>

      
    </div>
    <div class="action_button full primary" (click)="presentConfirmRequest()">إرسال الطلب</div>
  </div>

  <div *ngIf="request_submitted" style="padding: 20px; height: 70vh; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;">
    <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M32.5007 59.3499C47.2282 59.3499 59.1673 47.4109 59.1673 32.6833C59.1673 17.9557 47.2282 6.0166 32.5007 6.0166C17.7731 6.0166 5.83398 17.9557 5.83398 32.6833C5.83398 47.4109 17.7731 59.3499 32.5007 59.3499ZM47.2482 26.0975C48.0292 25.3164 48.0292 24.0501 47.2482 23.2691C46.4672 22.488 45.2008 22.488 44.4198 23.2691L29.834 37.8548L23.2482 31.2691C22.4672 30.488 21.2008 30.488 20.4198 31.2691C19.6387 32.0501 19.6387 33.3164 20.4198 34.0975L28.4198 42.0975C28.7948 42.4726 29.3036 42.6833 29.834 42.6833C30.3644 42.6833 30.8731 42.4726 31.2482 42.0975L47.2482 26.0975Z" fill="#6529F7"/></svg>
    <h3>تم إرسال طلبك بنجاح</h3>
    <p>بإمكانك متابعة الطلب والمحادثة مع المصمم من خلال قائمة المشاريع</p>

    <div class="action_button full primary" style="margin-top: 50px" (click)="closeRequest()">القائمة الرئيسية</div>
  </div>

  <div style="height: 300px"></div>

</ion-content>

<loading></loading>
<modal></modal>