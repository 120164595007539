import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpService } from '../services/http.service';
import { UtilService } from '../services/util.service';
import { skills_categories_mapping } from '../services/util.service';
import { api_path } from 'src/environments/environment';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { UserService } from '../services/user.service';
import { Camera, CameraResultType } from '@capacitor/camera';
import { LoadingComponent } from '../components/loading/loading.component';
import { ModalComponent } from '../components/modal/modal.component';
import { NavigationExtras, Router } from '@angular/router';
import { AddServicePage } from '../add-service/add-service.page';
import { NewRequestPage } from '../new-request/new-request.page';
import { LoginModalPage } from '../login-modal/login-modal.page';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.page.html',
  styleUrls: ['./service-details.page.scss', '../add-service/add-service.page.scss'],
})
export class ServiceDetailsPage implements OnInit {

  @ViewChild(LoadingComponent) loadingComponent;
  @ViewChild(ModalComponent) modalComponent;

  // @Input('service') service;
  // @Input('user') user;
  service;
  user;
  packages = [];
  skills_mapping = [];
  chosen_skills = [];
  deliverables = [];
  batches = [];
  service_status = false;
  thumb = '';

  editMode = false;
  editPackageMode = false;
  edit_package_index = -1;

  loadingPackages = false;
  loadingARequest = false;

  api_path = api_path

  constructor(
    public util: UtilService,
    private httpService: HttpService,
    private userService: UserService,
    private modalController: ModalController,
    private navCtrl: NavController,
    private alertController: AlertController,
    private router: Router
  ) { }

  ngOnInit() {

    this.userService.getUserData().then(res => {
      this.user = JSON.parse(res.value);
      if (this.user == null) {
        this.router.navigate(['/login']);
        return;
      }

      // console.log("user")
      // console.log(this.user)

      this.userService.getServiceData().then(res => {
        this.service = JSON.parse(res.value);
        // console.log("service")
        // console.log(this.service)
        this.deliverables = this.service.ServicePackages[0].description.split(',');
        this.batches = this.service.ServiceBatches.map(b => (b.percentage*100));
        if (this.service.Services.status == 'Active') {
          this.service_status = true;
        } else {
          this.service_status = false;
        }

        if (this.service.ServiceImages.length == 0) {
          this.thumb = '/assets/steps_bg.png';
        } else {
          this.thumb = api_path + '/' + this.service.ServiceImages[0].File.url;
        }
        
        // this.userService.getSkillsData().then(res => {
        //   var skills_categories_mapping = JSON.parse(res.value);
        //   this.skills_mapping = Object.keys(skills_categories_mapping).map((key) => skills_categories_mapping[key]);
        // });
      });
    })
  }

  chatWithDesigner() {

    if (this.loadingARequest) {
      return;
    } else {
      this.loadingARequest = true;
    }
    this.loadingComponent.show();

    var url = `v1/services/createChatForService?service_id=${this.service.Services.id}`;
    this.httpService.doPost(url, {}, this.user.jwt).then((res) => {
      // console.log('createChatForService res')
      // console.log(res)

      if (res.status == 200) {
        
        var request_id = res.data.data.request_id;
        this.modalController.dismiss(request_id);
        // this.navCtrl.navigateBack([`/client/chat/${request_id}`]);

      } else if (res.status == 401) {
        this.util.goToHome(this.user.type);
      }

    })
    .catch(err => {
      console.log("err")
      console.log(err)
    }).finally(() => {
      this.loadingARequest = false;
    });
  }

  async makeNewRequestFromService() {

    if (this.user.token == 'guest') {
      // this.goToLoginForRegistration();
      this.showLoginModal();
      return;
    }
    
    let opts = {
      component: NewRequestPage,
      componentProps: {
        'service': this.service
      }
    }

    opts["breakpoints"] = [0, 0.96];
    opts["initialBreakpoint"] = 0.96;
    opts["canDismiss"] = (data, role) => role !== 'gesture';

    const modal = await this.modalController.create(opts);

    modal.onDidDismiss()
      .then((res) => {
        const data = res['data'];
        this.modalController.dismiss();
    });

    return await modal.present();
  }

  async promptRequestService(package_id) {

    const msg = `<p>هل أنت متأكد من طلب الخدمة ؟</p>`;

    const msgalert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'طلب خدمة',
      message: msg,
      buttons: [
        {
          text: 'الغاء',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'طلب',
          id: 'confirm-button',
          handler: () => {
            this.requestService(package_id);
          }
        }
      ]
    });

    await msgalert.present();
  }

  async goToLoginForRegistration() {

    const msg = `<p>سجل دخولك لتبدأ بطلب اول خدمة!</p>`;

    const msgalert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'تسجيل الدخول',
      message: msg,
      buttons: [
        {
          text: 'الغاء',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'تسجيل الدخول',
          id: 'confirm-button',
          handler: () => {
            
            this.userService.clearStorageData().then(res => {
              this.modalController.dismiss();
              this.router.navigate(['/login/User']);
            });

          }
        }
      ]
    });

    await msgalert.present();
  }

  async showLoginModal() {
    let opts = {
      component: LoginModalPage,
      componentProps: {
        'source': 'service'
      }
    }

    opts["breakpoints"] = [0, 0.4];
    opts["initialBreakpoint"] = 0.4;

    const modal = await this.modalController.create(opts);

    modal.onDidDismiss()
      .then((res) => {
        this.userService.getUserData().then(res => {
          this.user = JSON.parse(res.value);
        })
      });

    return await modal.present();
  }

  async promptEditService() {

    const msg = `<p>تأكيد التعديل ؟</p>`;

    const msgalert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'تعديل الخدمة',
      message: msg,
      buttons: [
        {
          text: 'الغاء',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'تعديل',
          id: 'confirm-button',
          handler: () => {
            this.editService();
          }
        }
      ]
    });

    await msgalert.present();
  }

  async promptEditPackage(index) {

    var msg = `<p>تأكيد التعديل ؟</p>`;
    if (this.service.ServicePackages[this.edit_package_index].new) {
      msg = `<p>تأكيد الإضافة ؟</p>`;
    }

    var header = 'تعديل الباقة';
    if (this.service.ServicePackages[this.edit_package_index].new) {
      header = 'إضافة الباقة';
    }

    const msgalert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: msg,
      buttons: [
        {
          text: 'الغاء',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'تأكيد',
          id: 'confirm-button',
          handler: () => {
            var invalid_items = this.validatePackageBeforeAdd(this.edit_package_index);
            invalid_items.items = [ ...new Set(invalid_items.items) ];
            if (invalid_items.status == true) {
              if (!this.service.ServicePackages[this.edit_package_index].new) {
                this.editPackage(index, 'existing');
              } else {
                this.editPackage(index, 'new');
              }
            } else {
              this.presentIncompleteItems(invalid_items.items)
            }
          }
        }
      ]
    });

    await msgalert.present();
  }

  async promptRemoveServiceImage(i) {

    const msg = `<p>تأكيد حذف الصورة ؟</p>`;

    const msgalert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'حذف الصورة',
      message: msg,
      buttons: [
        {
          text: 'الغاء',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'تأكيد',
          id: 'confirm-button',
          handler: () => {
            this.removeServiceImage(i);
          }
        }
      ]
    });

    await msgalert.present();
  }

  async promptDeleteService() {

    const msg = `<p>تأكيد الحذف ؟</p>`;

    const msgalert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'حذف الخدمة',
      message: msg,
      buttons: [
        {
          text: 'الغاء',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'طلب',
          id: 'confirm-button',
          handler: () => {
            this.deleteService();
          }
        }
      ]
    });

    await msgalert.present();
  }

  async promptDeletePackage(index) {

    const msg = `<p>تأكيد الحذف ؟</p>`;

    const msgalert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'حذف الباقة',
      message: msg,
      buttons: [
        {
          text: 'الغاء',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'طلب',
          id: 'confirm-button',
          handler: () => {
            this.deletePackage(index);
          }
        }
      ]
    });

    await msgalert.present();
  }

  requestService(package_id) {

    var url = `v1/services/createRequestForService?service_id=${this.service.Services.id}&package_id=${package_id}`;
    this.httpService.doPost(url, this.user.jwt).then((res) => {
      // console.log('res')
      // console.log(res)

      if (res.status == 200) {
        
        var request_id = res.data.data;
        this.modalController.dismiss();
        this.navCtrl.navigateBack([`/home/${request_id}`]);

      } else if (res.status == 401) {
        this.util.goToHome(this.user.type);
      }

    })
    .catch(err => {
      console.log("err")
      console.log(err)
    }).finally(() => {
      this.loadingComponent.hide();
    });
  }

  goToEditMode() {
    this.editMode = true;
  }

  cancelEditMode() {
    this.editMode = false;
  }

  enterEditPackageMode(i) {
    this.editPackageMode = true;
    this.edit_package_index = i;
  }

  cancelEditPackageMode() {
    if (this.service.ServicePackages[this.edit_package_index].new) {
      this.service.ServicePackages.splice(this.edit_package_index, 1);
    }
    this.editPackageMode = false;
    this.edit_package_index = -1;
  }

  editService() {

    var title = (<HTMLInputElement>document.getElementById("service_title")).value;
    var description = (<HTMLInputElement>document.getElementById("service_description")).value;

    if (title != '') {
      title = encodeURIComponent(title)
    } else {
      alert('لازم تكتب عنوان');
      return
    }
    if (description != '') {
      description = encodeURIComponent(description) 
    } else {
      alert('لازم تكتب وصف');
      return
    }

    const body = {
      "title": title,
      "description": description,
      "skill_id": this.service.Services.skill_id,
      "status": "Active"
    }

    var url = `v1/services/updateServiceOnly?service_id=${this.service.Services.id}`;

    // console.log("body: " + JSON.stringify(body));
    this.httpService.doPut(url, body, this.user.jwt).then((res) => {
      // console.log("res");
      // console.log(res);
      if (res.status == 200) {

        this.service.Services.title = decodeURIComponent(title);
        this.service.Services.description = decodeURIComponent(description);
        this.editMode = false;
      } else if (res.status == 401) {
        this.util.goToHome(this.service.Services.type);
      }
    })
      .catch(err => {
        // this.modalComponent.show("خلل عند ارسال الطلب" + JSON.stringify(err));
      })
  }

  editPackage(i, type='existing') {
    this.loadingComponent.show();

    var p_description = (<HTMLInputElement>document.getElementById(`package_description_${i}`)).value;
    var p_price = (<HTMLInputElement>document.getElementById(`package_price_${i}`)).value;
    var p_days = (<HTMLInputElement>document.getElementById(`package_days_${i}`)).value;

    if (p_description != '') {
      p_description = encodeURIComponent(p_description)
    } else {
      alert('لازم تكتب وصف');
      return
    }
    if (p_price != '') {
      var i_p_price = parseInt(p_price)
    } else {
      alert('لازم تكتب سعر');
      return
    }
    if (p_days != '') {
      var i_p_days = parseInt(p_days)
    } else {
      alert('لازم تكتب مدة تسليم');
      return
    }

    if (type == 'existing') {
      var url = `v1/services/updateServicePackage?package_id=${this.service.ServicePackages[i].id}`;
      var body = {
        "description": p_description,
        "price": i_p_price,
        "days": i_p_days,
      }

      // console.log("body: " + JSON.stringify(body));
      this.httpService.doPut(url, body, this.user.jwt).then((res) => {
        // console.log("res");
        // console.log(res);
        if (res.status == 200) {

          this.service.ServicePackages[i].description = decodeURIComponent(p_description);
          this.service.ServicePackages[i].price = p_price;
          this.service.ServicePackages[i].days = p_days;

          this.editPackageMode = false;
          this.edit_package_index = -1;

        } else if (res.status == 401) {
          this.util.goToHome(this.service.Services.type);
        }
      })
      .catch(err => {
        // this.modalComponent.show("خلل عند ارسال الطلب" + JSON.stringify(err));
      }).finally(()=>{this.loadingComponent.hide();})

      
    } else { // new
      var new_body = {
        "service_id": this.service.Services.id,
        "packages": [
          {
            "description": p_description,
            "price": i_p_price,
            "days": i_p_days,
          }
        ]
      }
      url = `v1/services/createServicePackage`;

      console.log("body: " + JSON.stringify(body));
      this.httpService.doPost(url, new_body, this.user.jwt).then((res) => {
        // console.log("res");
        // console.log(res);
        if (res.status == 200) {

          this.service.ServicePackages[i].description = decodeURIComponent(p_description);
          this.service.ServicePackages[i].price = p_price;
          this.service.ServicePackages[i].days = p_days;
          this.service.ServicePackages[i].new = false;

          this.editPackageMode = false;
          this.edit_package_index = -1;

        } else if (res.status == 401) {
          this.util.goToHome(this.service.Services.type);
        }
      })
      .catch(err => {
        // this.modalComponent.show("خلل عند ارسال الطلب" + JSON.stringify(err));
      }).finally(()=>{this.loadingComponent.hide();})


    }
  }

  setServicesActive() {

    var title = (<HTMLInputElement>document.getElementById("service_title")).value;
    var description = (<HTMLInputElement>document.getElementById("service_description")).value;

    if (title != '') {
      title = encodeURIComponent(title)
    } else {
      alert('لازم تكتب عنوان');
      return
    }
    if (description != '') {
      description = encodeURIComponent(description) 
    } else {
      alert('لازم تكتب وصف');
      return
    }

    const body = {}

    var url = `v1/services/updateServiceOnly?service_id=${this.service.Services.id}`;

    // console.log("body: " + JSON.stringify(body));
    this.httpService.doPut(url, body, this.user.jwt).then((res) => {
      // console.log("res");
      // console.log(res);
      if (res.status == 200) {

        this.service.Services.title = decodeURIComponent(title);
        this.service.Services.description = decodeURIComponent(description);
        this.editMode = false;
      } else if (res.status == 401) {
        this.util.goToHome(this.service.Services.type);
      }
    })
      .catch(err => {
        // this.modalComponent.show("خلل عند ارسال الطلب" + JSON.stringify(err));
      })
  }

  async promptAddImageToService() {
    // const image = await Camera.getPhoto({
    //   quality: 100,
    //   allowEditing: true,
    //   resultType: CameraResultType.DataUrl
    // });
    const images = await Camera.pickImages({
      quality: 100,
      limit: 5
    });

    var designs = [];
    for (const image of images.photos) {
      var image_path = image.path;

      if (!this.util.isMobile()) {
        image_path = image.webPath;
      }
      
      const blob = await this.util.fileToBlob(image_path);
      designs.push(blob);
    }
    this.uploadServiceImage(designs, this.service.Services.id);

  };

  uploadServiceImage(designs, service_id) {

    this.loadingComponent.show('جاري رفع تصميم ');
    
    var url = `file/uploadServiceImages?service_id=${service_id}`;
    this.httpService.uploadFile(url, this.user.jwt, designs, 'multiple').then((res) => {

      if (res.status == 200) {
        // console.log("uploadServiceImage res")
        // console.log(res);
        this.service.ServiceImages.push(res.data.data.Files[0]);
      }

    })
      .catch(err => {
        console.log("uploadServiceImage err")
        console.log(err)
      }).finally(()=>{
        this.loadingComponent.hide();
      });
  }

  async presentEditServicePage() {

    var props = {
      'service': this.service
    }

    let opts = {
      component: AddServicePage,
      componentProps: props
    }

    opts["breakpoints"] = [0, 0.9];
    opts["initialBreakpoint"] = 0.9;
    opts["canDismiss"] = (data, role) => role !== 'gesture';

    const modal = await this.modalController.create(opts);

    modal.onDidDismiss()
      .then((res) => {
        const data = res['data'];
        if (data.update) {
          // update service details
        }
      });

    return await modal.present();
  }

  deleteService() {
    this.loadingComponent.show('جاري حذف الخدمة');

    var url = `v1/services/deleteService?service_id=${this.service.Services.id}`;
    
    this.httpService.doDelete(url, this.user.jwt).then((res) => {

      // console.log("deleteService res")
      // console.log(res)

      if (res.status == 200) {
        this.navCtrl.navigateBack(['/service']);
      }
      else if (res.status == 401) {
        this.util.goToHome(this.user.type);
      }

    })
      .catch(err => {
        console.log("deleteService err")
        console.log(err)
      }).finally(()=>{
        this.loadingComponent.hide();
      });
  }

  deletePackage(index) {
    this.loadingComponent.show('جاري حذف الخدمة');

    var url = `v1/services/deleteServicePackage?package_id=${this.service.ServicePackages[index].id}`;
    
    this.httpService.doDelete(url, this.user.jwt).then((res) => {

      // console.log("deletePackage res")
      // console.log(res)

      if (res.status == 200) {
        this.service.ServicePackages.splice(index, 1);
        this.editPackageMode = false;
        this.edit_package_index = -1;
      }
      else if (res.status == 401) {
        this.util.goToHome(this.user.type);
      }

    })
      .catch(err => {
        console.log("deletePackage err")
        console.log(err)
      }).finally(()=>{
        this.loadingComponent.hide();
      });
  }

  addNewPackageRow() {
    if (this.service.ServicePackages != null && this.service.ServicePackages.length >= 3) {
      alert("لا يمكن اضافة اكثر من ٣ باقات");
      return;
    } else {
      this.service.ServicePackages.push({
        "description": "لا يوجد",
        "price": 0,
        "days": 0,
        "new": true
      });

      this.editPackageMode = true;
      this.edit_package_index = this.service.ServicePackages.length-1;
    }
  }

  removePackage(index) {
    this.service.ServicePackages.splice(index, 1);
  }

  removeServiceImage(index) {
    this.loadingComponent.show('جاري حذف التصميم');

    var url = `v1/services/deleteServiceImage?service_image_id=${this.service.ServiceImages[index]["ServiceImages"]["id"]}`;
    
    this.httpService.doDelete(url, this.user.jwt).then((res) => {

      // console.log("removeServiceImage res")
      // console.log(res)

      if (res.status == 200) {
        this.service.ServiceImages.splice(index, 1);
      }
      else if (res.status == 401) {
        this.util.goToHome(this.user.type);
      }

    })
      .catch(err => {
        console.log("removeServiceImage err")
        console.log(err)
      }).finally(()=>{
        this.loadingComponent.hide();
      });
  }

  validatePackageBeforeAdd(i) {

    var invalid_items = {
      status: true,
      items: [],
    };
      
    var description = (<HTMLInputElement>document.getElementById(`package_description_${i}`)).value;
    var price = (<HTMLInputElement>document.getElementById(`package_price_${i}`)).value;
    var days = (<HTMLInputElement>document.getElementById(`package_days_${i}`)).value;

    if (description == '') {
      invalid_items.status = false;
      invalid_items.items.push('يجب ادخال شرح للباقة');
    }
    if (price == '') {
      invalid_items.status = false;
      invalid_items.items.push('(أرقام انجليزية) يجب ادخال سعر للباقة');
    }
    if (!this.util.containsOnlyNumbers(price)) {
      invalid_items.status = false;
      invalid_items.items.push('لازم السعر يتكون من أرقام فقط');
    }
    if (days == '') {
      invalid_items.status = false;
      invalid_items.items.push('(أرقام انجليزية) يجب ادخال مدة تسليم المشروع');
    }
    if (!this.util.containsOnlyNumbers(days)) {
      invalid_items.status = false;
      invalid_items.items.push('لازم المدة يتكون من أرقام فقط');
    }

    return invalid_items;
  }

  async presentIncompleteItems(items) {

    var msg = ``

    msg += `
      <ul class='invalid_list'>
    `
    items.forEach(item => {
      msg += `<li>${item}</li>`
    });

    msg += `
      </ul>
    `

    const alert = await this.alertController.create({
      cssClass: 'incomplete-profile',
      header: 'معلومات الخدمة غير مكتملة',
      message: msg,
      buttons: []
    });

    await alert.present();
  }

  async presentDesignerDetails(d) {

    // if not logged in, login as guest
    if (this.user == null) {
      this.user = this.util.setUserAsGuest();
    }

    this.util.saveData("designer", d).then(() => {
      this.navCtrl.navigateForward(['/designer-info']);
    });
  }

  back() {
    if (this.user.type == 'Designer') {
      this.navCtrl.navigateBack(['/service']);
    } else {
      // this.navCtrl.navigateBack(['/services-explorer']);
      this.navCtrl.navigateBack(['/client']);
    }
  }

}
