<div class="modal" *ngIf="shown" [@fade]>
  <ion-row>
    <ion-col style="padding: 0;">
      <!-- Modal content -->
      <div class="modal-content" *ngIf="contentShown" [@slide]>
        <div id="close" style="cursor: pointer;" (click)="hide()">&times;</div>
        <h1></h1>
        <p class="content">{{content}}</p>
      </div>
    </ion-col>
  </ion-row>
</div>