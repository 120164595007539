<ion-content>
  <div class="image_options">
    <div class="square_button" (click)="dismiss()">
      <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L15 7L9 13" stroke="black" stroke-width="2"/><line x1="15" y1="6.5" y2="6.5" stroke="black" stroke-width="2"/></svg>
    </div>
  </div>
  <div *ngIf="image_urls.length == 0" class="image_wrapper">
    <img alt="image_url" [src]="image_url" />
  </div>

  <div *ngIf="image_urls.length > 0" class="images_wrapper" id="images_wrapper">
    <img *ngFor="let url of image_urls" alt="image_url" [src]="url" />
  </div>

</ion-content>