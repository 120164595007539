import { Component, OnInit, ViewChild, isDevMode, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';
import { UtilService } from 'src/app/services/util.service';
import '@codetrix-studio/capacitor-google-auth';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';

import { Capacitor, Plugins } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { LoadingComponent } from '../components/loading/loading.component';
import { ModalComponent } from '../components/modal/modal.component';

declare var google: any;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.page.html',
  styleUrls: ['./login-modal.page.scss'],
})

export class LoginModalPage implements OnInit {

  @ViewChild(LoadingComponent) loadingComponent;
  @ViewChild(ModalComponent) modalComponent;

  @Input('source') source = 'login';
  
  user_type = 'Designer';
  platform = Capacitor.getPlatform();
  userData;
  dev_mode;

  constructor(
    private userService: UserService,
    private httpService: HttpService,
    private signInWithApple: SignInWithApple,
    public util: UtilService,
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController
  ) { }

  ngOnInit() {

    this.dev_mode = isDevMode();

    // setTimeout(() => {
    //   this.initGoogleButton();
    // }, 1000);
  }

  initGoogleButton() {
    if (this.platform != 'ios' && this.platform != 'android') {
      // console.log("google.accounts.id.initialize")
      google.accounts.id.initialize({
        client_id: "296389785650-do2dal05qn9cg273lfvsh0bk30blf4t7.apps.googleusercontent.com",
        callback: this.handleCredentialResponse.bind(this)
      });
      google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          type: 'standard'
        }
      );
      google.accounts.id.prompt(); // also display the One Tap dialog
    }
  }

  appleSignIn() {

    this.signInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    })
      .then((res: AppleSignInResponse) => {
        // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
        // alert('Send token to apple for verification: ' + res.identityToken);
        // console.log("applesignin success");
        // console.log("res");
        // console.log(res);

        // {"email":"eiddesigner37@gmail.com","authorizationCode":"c6c27f8ae4c904ff4b73d04ae7fa3c088.0.rvqr.dOjgh_PPS9CH_wgyvsnE2Q","state":"","identityToken":"eyJraWQiOiJZdXlYb1kiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwcGxlaWQuYXBwbGUuY29tIiwiYXVkIjoiaW8ua29hcHBzLmJldGFrdGkiLCJleHAiOjE2Mzc5ODc3MDIsImlhdCI6MTYzNzkwMTMwMiwic3ViIjoiMDAwNTAxLmMwZjZkYTE4M2YyMzQzZWVhMTA4NTJjZTc2YjAzMzBjLjA0MzUiLCJjX2hhc2giOiJwVU4zTU9nNkVLQUZjeW5HT1dFRnh3IiwiZW1haWwiOiJlaWRkZXNpZ25lcjM3QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjoidHJ1ZSIsImF1dGhfdGltZSI6MTYzNzkwMTMwMiwibm9uY2Vfc3VwcG9ydGVkIjp0cnVlLCJyZWFsX3VzZXJfc3RhdHVzIjoyfQ.SP9pdcGYNgrPwIhOvRZJV03rFLe9UP--9IgTuF2zBxsmHm9MRQGf29Q51_rKSk7hmPWkrB2rL2lZoGqn3nLlfoxDrDzw4gmQTK_jr2XeB0YLW1v-gDxASAOulfBitQixkHIF66KvlOOzmg-G1FxwvlJNdhgcnLZH9ffiz_TQII8gUqZi19sLW_MiXriNEZoGKBsToy-aXdI-jah9URhrih6Gu9kRYdumvDKBMG1-ekQqvq3GXg0h2rUiIepS3r3aWdwYFdVbBI83IjD6TV4Y51C8VklRaQBVanOFbD3nVKMr7F48h3Vti5L69MYYU4uur4B6aSq2DWXchG_DHaxFSA","fullName":{"nickname":"","phoneticRepresentation":{},"middleName":"","familyName":"Kcapps","namePrefix":"","givenName":"KOApps","nameSuffix":""},"user":"000501.c0f6da183f2343eea10852ce76b0330c.0435"}

        this.loadingComponent.show('جاري تسجيل الدخول');
        var appleData = res;
        // console.log("apple data")
        // console.log(appleData)

        // HTTP Call
        const body = {
          "token": appleData.identityToken,
          "email": appleData.email,
          "auth_provider": "Apple",
          "display_name": `${appleData.fullName.givenName} ${appleData.fullName.familyName}`,
          "phone": "",
          "used_referral_code": ""
        }

        this.completeUserRegistration(body.token, body.email, body.display_name, this.user_type, 'Apple');

      })
      .catch((error: AppleSignInErrorResponse) => {
        console.error("login error " + error.code + ' ' + error.localizedDescription);
        console.error(error);
        this.modalComponent.show("خلل في تسجيل الدخول Apple" + JSON.stringify(error));
      }).finally(() => {
        this.loadingComponent.hide();
      });
  }

  async googleSignIn(type='') {
    this.loadingComponent.show();

    // if (this.platform == 'android') {

    //   // designer
    //   var body = {
    //     "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImEwNmFmMGI2OGEyMTE5ZDY5MmNhYzRhYmY0MTVmZjM3ODgxMzZmNjUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIyMTkzNTgxNjg3ODktYWI0c3NsaTJsNmNqcjNkdWNvZzZsOGZsb2s2OTA1cjEuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyMTkzNTgxNjg3ODktbmNrN2Vha2lzN2k4YmdyaG5zZDlmbXRlcWNqMWdpdnEuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTE3Nzg3MTM4ODM3OTYyOTg2NDciLCJlbWFpbCI6ImtoYWxpZC5qaDk0QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiS2hhbGlkIEpoIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0pQeW5xTXNWWGZoYUZTbEV6d1k5ODlpTkFlWDAtdDVod2t3eDNZQldMeDhzUT1zOTYtYyIsImdpdmVuX25hbWUiOiJLaGFsaWQiLCJmYW1pbHlfbmFtZSI6IkpoIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE2OTgyNDg2NzAsImV4cCI6MTY5ODI1MjI3MH0.C_sE-1CLduFpxWyAJhsHFHHAFkzmZOVohxTQKmpJdCdzNOMPa6Y_BTYim1g_YsAo524xoEZzTfymQu84I80M8vcjq3uHXBMMCFjKdm52yV4UVuTYrPdEzX2-QqTzMsL5EG5MRvCqkovrQOpWCcosSs4Q8hWx4eshA2ZzPjdhMWJbm_cHp2oRcZjz2EKgN3PmZrPGwmWZLeE9tX86pKw0WDj_AEfSCEJTNYJ7NT5qY-1Ny17_F7WmVUudBJl8c4pOiFVC1x54WLGoC72AlLFaxut4ZZQdBf_9HIig4_whAnutgEPCWwlUbM9QMo5hH0jCi-ATNOM7xDewMNDVpM329w",
    //     "email": "khalid.jh94@gmail.com",
    //     "auth_provider": "Google",
    //     "display_name": "Khalid JH",
    //     "phone": "",
    //     "used_referral_code": "",
    //   }

    //   // client
    //   // if (this.user_type == 'User') {
    //   //   body = {   
    //   //     "token": "eyJhbGciOiJSUz984rurfrGFGHUfdZDU4Yzk5NTVkNGYyNDBiNmE5MmY3YjM3ZmVhZDJmZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NTYwNzc4NDMsImF1ZCI6IjIxOTM1OD=fhuirk",
    //   //     "email": "omar33@gmail.com",
    //   //     "display_name": "عمر حماد",
    //   //     "phone": "",
    //   //     "used_referral_code": ""
    //   //   }
    //   // }
      
    //   this.completeUserRegistration(body.token, body.email, body.display_name, this.user_type, "Google");
    //   return;
    // }

    if (this.platform == 'web') {
      console.log("googleSignIn_web")
      this.googleSignIn_web(type);
      this.loadingComponent.hide();
    } else {

      try {
        const googleData = await Plugins.GoogleAuth.signIn({ prompt: 'select_account' }) as any;
        // console.log('my user: ', googleData);
        this.handleCredentialResponse('','mobile',this.user_type,googleData);
      } catch (e) {
        console.log("googleSignIn error")
        console.log(e)
      } finally {
        this.loadingComponent.hide();
      }
      

      // const checkbox = document.getElementById('myCheckbox');
      // if (!(<HTMLInputElement>checkbox).checked) {
      //   alert('يجب الموافقة على الشروط اولا');
      //   this.loadingComponent.hide();
      // } else {
      // this.googlePlus.login({})
      // .then((result) => {
      //   this.loadingComponent.show('جاري تسجيل الدخول');
      //   var googleData = result;
      //   this.handleCredentialResponse(result,'mobile','Designer',googleData);
      // })
      // .catch((err) => {
      //   console.log("خلل في تسجيل الدخول Google mob" + err);
      //   console.log(err);
      //   console.log(JSON.stringify(err));
      // }).finally(() => { this.loadingComponent.hide(); });
    }
  }

  googleSignIn_web(type='') {
    console.log("googleSignIn_web type " + type);
    this.loadingComponent.show('جاري تسجيل الدخول');

    if (this.dev_mode) {
      this.handleCredentialResponse("",'web',type,null);
    } else {
      console.log("google.accounts.id.initialize");
      google.accounts.id.initialize({
        client_id: "296389785650-do2dal05qn9cg273lfvsh0bk30blf4t7.apps.googleusercontent.com",
        callback: this.handleCredentialResponse.bind(this)
      });
      google.accounts.id.prompt();
    }
  }

  async handleCredentialResponse(response, source='mobile', type='Designer',googleData=null) {

    console.log("handleCredentialResponse")

    if (this.platform != 'web') {
      // send google data to server to register
      var body = {
        "token": googleData['authentication']['idToken'],
        "email": googleData['email'],
        "display_name": googleData['displayName'],
        "auth_provider": "Google",
        "phone": "",
        "used_referral_code": ""
      }

      if (this.platform == 'ios') {
        // body["token"] = googleData['accessToken'];
        body["display_name"] = googleData['name'];
      }

      // googleData['imageUrl']
    } else {
      // console.log("handleCredentialResponse")
      // Here we can do whatever process with the response we want
      // Note that response.credential is a JWT ID token
      var token = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjJiMDllNzQ0ZDU4Yzk5NTVkNGYyNDBiNmE5MmY3YjM3ZmVhZDJmZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NTYwNzc4NDMsImF1ZCI6IjIxOTM1ODE2ODc4OS1uY2s3ZWFraXM3aThiZ3JobnNkOWZtdGVxY2oxZ2l2cS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTc3ODcxMzg4Mzc5NjI5ODY0NyIsImVtYWlsIjoia2hhbGlkLmpoOTRAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF6cCI6IjIxOTM1ODE2ODc4OS1uY2s3ZWFraXM3aThiZ3JobnNkOWZtdGVxY2oxZ2l2cS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsIm5hbWUiOiJLaGFsaWQgSmgiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2phOHhwUC0yTE1vcVh4X19ObHAxWWpLc0ZkRzBFcDlPWE1IY080N2c9czk2LWMiLCJnaXZlbl9uYW1lIjoiS2hhbGlkIiwiZmFtaWx5X25hbWUiOiJKaCIsImlhdCI6MTY1NjA3ODE0MywiZXhwIjoxNjU2MDgxNzQzLCJqdGkiOiJmM2VlY2I0MGNiMDc1MWY2Y2M2ZTAwZWQ5ZDg1M2E2NjFkNDU1Zjc3In0.k9kxQf3rbLrI2xWUKg9_WSqU0jG96E9XsyLEr3kicq-brrfmm9GL4zwjORRvVPCJRmcOqZSUnAo0HSMKJRdeXQTCaJrK54Pymt5tk61I5wp8nyWq2X2bYmG0BjoPqzkNUQu9sIzos4mwTn_XtmeV7DzfaAVl6BmsvSsnm1WNGoE59JyiW-_0TCaaTdLR7zZhRQdfLgfABzNwthvLiRWsd_RrYpI6FTZYVOa4yItQQNfn_pktkNYm_rLHchytDwjXjF5o0p30cRFZa9GtR7DqvKHA6tL-SY1s5BwoNn3TuZuhDoc4VR9kJeeZTA4VgWq4ikAYOqHZMiRMgdMLBLuxkQ"
      if (response != "") {
        token = response.credential;
      }
      // var token = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjJiMDllNzQ0ZDU4Yzk5NTVkNGYyNDBiNmE5MmY3YjM3ZmVhZDJmZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NTYwODIyOTgsImF1ZCI6IjIxOTM1ODE2ODc4OS1uY2s3ZWFraXM3aThiZ3JobnNkOWZtdGVxY2oxZ2l2cS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTc3ODcxMzg4Mzc5NjI5ODY0NyIsImVtYWlsIjoia2hhbGlkLmpoOTRAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF6cCI6IjIxOTM1ODE2ODc4OS1uY2s3ZWFraXM3aThiZ3JobnNkOWZtdGVxY2oxZ2l2cS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsIm5hbWUiOiJLaGFsaWQgSmgiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2phOHhwUC0yTE1vcVh4X19ObHAxWWpLc0ZkRzBFcDlPWE1IY080N2c9czk2LWMiLCJnaXZlbl9uYW1lIjoiS2hhbGlkIiwiZmFtaWx5X25hbWUiOiJKaCIsImlhdCI6MTY1NjA4MjU5OCwiZXhwIjoxNjU2MDg2MTk4LCJqdGkiOiJkYjJkZGQ5Mzg5MGM5ZmRiYjBhZTI1Yzg0OGIxNzk2NzZmZmYyN2Q2In0.RCrzIMYg55ghCnHqLHsyGjbeNB5_IUOV1vPAzt6S6vNrCbP1_A_rrYABHxE_6_UgFj-ipqA1B3SSqPKxZgCvau7bJx28-zmOIdcfV-2yzkGiNDJBJyeboK2qCIG7oRUaNSf3A8Dsl2pd-P8RAwAOFjJ4nBoO5cvnxsoXy4dCxd4Y_zHs7NpQ9vDAJ_JhH7yzApCMjwbAdxPP8C0aqeQ9qmuPLFFeGgCV0lPXcuFtk9jZzi6e568GogHjIo0Qm8mtIDNLchg21R1IyNJMiktOUWSHCBpFEYSNvT92WwerKmND4ANvjN2QOnfVd9C4SZ3oO_93SeMjxG4ZSS5Hdr_pnA"
      // console.log("Encoded JWT ID token: " + token);

      // var responsePayload = this.decodeJwtResponse(token);

      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      var responsePayload = JSON.parse(jsonPayload);
      // console.log("ID: " + responsePayload.sub);
      // console.log('Full Name: ' + responsePayload.name);
      // console.log('Given Name: ' + responsePayload.given_name);
      // console.log('Family Name: ' + responsePayload.family_name);
      // console.log("Image URL: " + responsePayload.picture);
      // console.log("Email: " + responsePayload.email);

      // send google data to server to register
      body = {
        "token": token,
        "email": responsePayload.email,
        "display_name": responsePayload.name,
        "auth_provider": "Google",
        "phone": "",
        "used_referral_code": ""
      }
    }

    if (this.dev_mode) {
      // body = {
      //   "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjJiMDllNzQ0ZDU4Yzk5NTVkNGYyNDBiNmE5MmY3YjM3ZmVhZDJmZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NTYwNzc4NDMsImF1ZCI6IjIxOTM1ODEjf7hkk",
      //   "email": "khalid.jh94@gmail.com",
      //   "display_name": "Khalid Hammad",
      //   "phone": "",
      //   "used_referral_code": ""
      // }

      body = {
        "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImEwNmFmMGI2OGEyMTE5ZDY5MmNhYzRhYmY0MTVmZjM3ODgxMzZmNjUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIyMTkzNTgxNjg3ODktYWI0c3NsaTJsNmNqcjNkdWNvZzZsOGZsb2s2OTA1cjEuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyMTkzNTgxNjg3ODktbmNrN2Vha2lzN2k4YmdyaG5zZDlmbXRlcWNqMWdpdnEuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTE3Nzg3MTM4ODM3OTYyOTg2NDciLCJlbWFpbCI6ImtoYWxpZC5qaDk0QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiS2hhbGlkIEpoIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0pQeW5xTXNWWGZoYUZTbEV6d1k5ODlpTkFlWDAtdDVod2t3eDNZQldMeDhzUT1zOTYtYyIsImdpdmVuX25hbWUiOiJLaGFsaWQiLCJmYW1pbHlfbmFtZSI6IkpoIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE2OTgyNDg2NzAsImV4cCI6MTY5ODI1MjI3MH0.C_sE-1CLduFpxWyAJhsHFHHAFkzmZOVohxTQKmpJdCdzNOMPa6Y_BTYim1g_YsAo524xoEZzTfymQu84I80M8vcjq3uHXBMMCFjKdm52yV4UVuTYrPdEzX2-QqTzMsL5EG5MRvCqkovrQOpWCcosSs4Q8hWx4eshA2ZzPjdhMWJbm_cHp2oRcZjz2EKgN3PmZrPGwmWZLeE9tX86pKw0WDj_AEfSCEJTNYJ7NT5qY-1Ny17_F7WmVUudBJl8c4pOiFVC1x54WLGoC72AlLFaxut4ZZQdBf_9HIig4_whAnutgEPCWwlUbM9QMo5hH0jCi-ATNOM7xDewMNDVpM329w",
        "email": "khalid.jh94@gmail.com",
        "auth_provider": "",
        "display_name": "Khalid JH",
        "phone": "",
        "used_referral_code": "",
      }
      // body = {
      //   "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY4MzNlOGE3ZmUzZmU0Yjg3ODk0ODIxOWExNjg0YWZhMzczY2E4NmYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIyMTkzNTgxNjg3ODktaTY1OWdkdXYzbnNoOGprMjkwNGxodGdtNzA5bjdmdTAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyMTkzNTgxNjg3ODktbmNrN2Vha2lzN2k4YmdyaG5zZDlmbXRlcWNqMWdpdnEuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMDE1OTc2NDg5MzQwMTYwNTE0NTkiLCJlbWFpbCI6ImtoYWxnb2dvQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiLWdVSklUNDQxNjJGbmN5ajFYZkJsUSIsIm5vbmNlIjoiUUk5NUNraElHckRxT0lueFNFY3BYcWpua0ZSeDJqdlVWbFkwRmQxNUhPbyIsIm5hbWUiOiJraGFsZ29nbyBoYW1tYWQiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jTHhpSE44d2czdW5IdHhOcEx2RVRiVk9mOFIwSEZWcXh0WDEtOENyRDlUPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6ImtoYWxnb2dvIiwiZmFtaWx5X25hbWUiOiJoYW1tYWQiLCJsb2NhbGUiOiJlbiIsImlhdCI6MTcwMDA1Nzk2MCwiZXhwIjoxNzAwMDYxNTYwfQ.e0qo0wz1f6S0se31-7_C1lqcZD3hM9f1iwx4UeIU4kSc45D-LHEtheAmbiOKd9aL1OMHgcNrez0dXTN5SGY-NwCoIGSBKrHVfQUkocGApvcq5jv4KdoOMQ3oAR1sItjOqkojrhFuwxjU-JzRiwuvRaoAto6T8PRHDpA90k-z8pK9xvtdBkler1Ux974wwX2_x99ZeBKODfkm67nCTijBlUlvPpttxgINewQbN3OP1YoYlLcJEtVKOpBoVj3fNCBM_xdFnZTLCdJqxqavnLwtb_W2ZTgb2UaOXAar5_uARnt72hyWh0wDsc_SyI97OjScFEAY1p_00HPJDifacADguA",
      //   "email": "khalgogo@gmail.com",
      //   "auth_provider": "Google",
      //   "display_name": "Hammadkj",
      //   "phone": "",
      //   "used_referral_code": "",
      // }

      if (type == "Designer") {
        // body = {
        //   "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY4MzNlOGE3ZmUzZmU0Yjg3ODk0ODIxOWExNjg0YWZhMzczY2E4NmYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIyMTkzNTgxNjg3ODktaTY1OWdkdXYzbnNoOGprMjkwNGxodGdtNzA5bjdmdTAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyMTkzNTgxNjg3ODktbmNrN2Vha2lzN2k4YmdyaG5zZDlmbXRlcWNqMWdpdnEuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTMwMDUzOTY5MzgzMTg3NzMwNzkiLCJlbWFpbCI6ImVpZGRlc2lnbmVyMzdAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiIwOEVKMmpXTUNjSWdZS3lHNkRQRi1RIiwibm9uY2UiOiJyUzEyWjdSV1dKaXpjTUlGaTEyYlU4MGJESHZYbWI2aHVqUTZ0TVRtYUZ3IiwibmFtZSI6IkJldGFrdGkgS08iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jS3hEa0tQSXJ0WWNNQ191M3JFUlBLM3JZeW5EV1QwRWg2YjVyd3FPMDgxWnc9czk2LWMiLCJnaXZlbl9uYW1lIjoiQmV0YWt0aSIsImZhbWlseV9uYW1lIjoiS08iLCJsb2NhbGUiOiJlbiIsImlhdCI6MTY5OTYxMDEyNCwiZXhwIjoxNjk5NjEzNzI0fQ.PiUjQcHRjNJe2Jtu7kHWpETo-HR5_OvWxMov37EUulS2qbUYFcNOeEnQkdbtiiVaOMvEDSbvHGzodvhFSRlTlXcSmGN9EPbfNLSWzVVrDE0ObJt7HNvIjTlWP66Me0-vRMb5XyMF_hKVD-s8GSW1O1VHkRumS7NOiK_UL2LUMVsHHraFCyOnX2qfl55xUv3-LG2IsAsn4phwvAWZn4TZ0GW1BQp-2cUaadhMeXWsch2nT69g3qcYS_iP9iT6sGojMhEz60vUBg-6be_A-RVANkik6ykisBtFhN-7ZUdNH_8B3gp1Cz_VYGjgC0WdXotsC2INDq29doo-9bbtbe7qbg",
        //   "email": "eiddesigner37@gmail.com",
        //   "display_name": "Betakti KO",
        //   "auth_provider": "Google",
        //   "phone": "",
        //   "used_referral_code": ""
        // }
        body = {
          "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImU0YWRmYjQzNmI5ZTE5N2UyZTExMDZhZjJjODQyMjg0ZTQ5ODZhZmYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIyMTkzNTgxNjg3ODktaTY1OWdkdXYzbnNoOGprMjkwNGxodGdtNzA5bjdmdTAuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiIyMTkzNTgxNjg3ODktbmNrN2Vha2lzN2k4YmdyaG5zZDlmbXRlcWNqMWdpdnEuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJzdWIiOiIxMTQ4MDI4NjE3NTQ0NjI4MDAwNzkiLCJlbWFpbCI6ImtoYWxpZGpzYWhAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiItRFJvYjEzOUVGYVVZZGNzS2dJQWxBIiwibm9uY2UiOiJja2N2MnVGS0hEcWNyOGpwaHppY0QxNllrWWRGMXlpLVJCT1VQTlVhRmtVIiwibmFtZSI6IktoYWxpZCBIYW1tYWQiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jSzRZY2JReTBqdWNtX1RVNXR1WURxSkpSSERGVlJZODAyWmN5ekk5VGEtQkE9czk2LWMiLCJnaXZlbl9uYW1lIjoiS2hhbGlkIiwiZmFtaWx5X25hbWUiOiJIYW1tYWQiLCJsb2NhbGUiOiJlbiIsImlhdCI6MTcwMTcwMDMzMCwiZXhwIjoxNzAxNzAzOTMwfQ.OGW06ibHab0y3faLOmZEKbuntyHr5IW8ExlJvezJU8O0PMc4muihK-n_FHzK5EmqjD-A0kvcIjX4lLHoK9H2GqxUiW_78W2gSMmuwTpbf1lAb3hatEasoimooFplIyHE9OAXp9u2rPaMlQcKbWYOGSCizLX1yBld4jcBr1MxIe9PWOB1DGvDoY8Jtkzou_WJ4NRtvzW2Pj7iEzZ1bTwPQYvRrdkTncAnlwVCJHe6nKqY8zyErDXyxjzbQiZYF9QfoLRb1_TP5dS1bXpjFLyGMiXRBCVpAVOWOdFJYWf_ciwW_PuiYSysdwsqsKHKRsZhE38wbfxfPj5g5VPwCnzsqg",
          "email": "khalidjsah@gmail.com",
          "display_name": "Khalid Hammad",
          "auth_provider": "",
          "phone": "",
          "used_referral_code": ""
        }
      }
    }

    this.completeUserRegistration(body.token, body.email, body.display_name, this.user_type, body.auth_provider);
  }

  async completeUserRegistration(id, email, name, type=this.user_type, auth_provider='Google') {

    var body = {
      "token": id,
      "email": email,
      "display_name": name,
      "phone": "",
      "auth_provider": auth_provider,
      "used_referral_code": "",
    }
    var url = `user/createDesigner`;
    if (type != 'Designer')
      url = `user/createUser`;

    // console.log("completeUserRegistration")
    // console.log(url)
    // console.log(body)

    // use this only if email and name is empty
    // if ((body.display_name == "" || body.display_name == " ") && body.email == "") {
    //   var generated_name = this.generateRandomString(id);
    //   body.display_name = `${generated_name}`;
    //   body.email = `${generated_name}@dzyni.com`;
    // }

    this.httpService.doPost(url, body).then((res) => {

      // console.log("completeUserRegistration res")
      // console.log(res)

      this.userData = res.data.data.User;
      this.userData.jwt = res.data.data.jwt;

      this.util.registerAmplitudeEvent('logged In');

      this.userService.saveUserData(this.userData).then(()=> {

        if (type == 'Designer')
          this.userData.type = "Designer";

        if (res.status == 201) {
          this.getMyDetails('existing');
        } else {
          this.getMyDetails('new');
        }
      })
    })
    .catch(err => {
      // console.log("completeUserRegistration خلل في تسجيل الدخول" + err);
      // console.log(err);
      // console.log(JSON.stringify(err));
      this.util.presentToast("bottom", "خلل في تسجيل الدخول");
      if (err.includes("The certificate for this server is invalid. You might be connecting to a server that is pretending to be")) {
        this.modalComponent.show('خلل في تسجيل الدخول: قم بتشغيل "الإعداد تلقائيًا" من "الإعدادات" > "عام" > "التاريخ والوقت".');
      } else {
        this.modalComponent.show("خلل في تسجيل الدخول" + err);
      }
    }).finally(() => {
      this.loadingComponent.hide();
    });
  }

  getMyDetails(type='') {
    this.loadingComponent.show();

    var url = `user/getMyDetails`;
    this.httpService.doGet(url, this.userData.jwt).then((res) => {

      if (res.status == 200) {
        if (this.platform == 'ios' || this.platform == 'android') {
          this.util.OneSignalInit(this.userData.id, this.userData.email);
        }

        // console.log("getMyDetails res")
        // console.log(res)

        // this.userData = res.data.User;
        this.userData.display_name = res.data.data.User.display_name;
        this.userData.email = res.data.data.User.email;
        this.userData.type = res.data.data.User.type;

        this.userData.image_url = res.data.data.File.thumb_url;
        this.userData.designs = res.data.data.Designs;
        this.userData.skills = res.data.data.Skills;
        this.userData.subskills = res.data.data.SubSkills;
        this.userData.accounts = res.data.data.Accounts;
        this.userData.reviews = res.data.data.Reviews;
        this.userData.bank_account = res.data.data.BankAccount;

        this.userService.saveUserData(this.userData).then(() => {

          if (this.source == 'login') {
            if (type == 'new' || this.userData.type == "Archived") {
              this.goToOnboardingPage();
            } else {
              this.goToUserPage();
            }
          }

          this.modalController.dismiss();
        });

      }
    })
      .catch(err => {
        console.log("خلل عند الحصول على معلوماتي" + JSON.stringify(err));
        console.log("خلل عند الحصول على معلوماتي" + err);
      }).finally(() => {this.loadingComponent.hide();});
  }

  generateRandomString(token) {
    // Convert the token into a numeric seed
    let seed = 0;
    for (let i = 0; i < token.length; i++) {
      seed += token.charCodeAt(i);
    }
  
    // Initialize the random number generator with the seed
    const seededRandom = (min, max) => {
      max = max || 1;
      min = min || 0;
      seed = (seed * 9301 + 49297) % 233280;
      const rnd = seed / 233280.0;
      return min + rnd * (max - min);
    };
  
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const stringLength = 20; // Change this to the desired length of your random string
  
    let randomString = '';
    for (let i = 0; i < stringLength; i++) {
      const randomIndex = Math.floor(seededRandom(0, characters.length));
      randomString += characters.charAt(randomIndex);
    }
  
    return randomString;
  }

  goToOnboardingPage() {
    console.log("goToOnboardingPage:");
    this.navCtrl.navigateRoot(['/desboarding']);
    // if (this.userData.type == "Designer") {
    //   this.navCtrl.navigateRoot(['/desboarding']);
    // } else {
    //   this.navCtrl.navigateRoot(['/onboarding']);
    // }
  }

  goToUserPage() {
    if (this.userData['type'] == "Designer") {
      // console.log("go to designer page")

        // console.log(this.userData.image_id == 1)
        // console.log(this.userData.phone == "")
        // console.log(this.userData.bio == null)
        // console.log(this.userData.hasOwnProperty("skills") && this.userData['skills'].length == 0)
        // console.log(this.userData.hasOwnProperty("designs") && this.userData['designs'].length == 0)

      if (
        this.userData.image_id == 1 &&
        this.userData.phone == "" &&
        this.userData.bio == null &&
        this.userData.hasOwnProperty("skills") && this.userData['skills'].length == 0 &&
        this.userData.hasOwnProperty("designs") && this.userData['designs'].length == 0
      ) {
        this.navCtrl.navigateRoot(['/desboarding']);
      } else {
        this.navCtrl.navigateRoot(['/home-designer/home']);
      }

    } else { // client
      this.navCtrl.navigateRoot(['/client']);
    }
  }

  async openUrl(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }
    await Browser.open({
      url: url,
      presentationStyle: 'popover'
    });
    // add listener
    Browser.addListener('browserFinished', () => {
      console.log('Browser has been dismissed');
    });
    Browser.addListener('browserPageLoaded', () => {
      console.log('Browser Page Loaded');
    });
  }

}
