import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { skills_categories_mapping, UtilService } from '../services/util.service';
import { LoadingComponent } from '../components/loading/loading.component';
import { ModalComponent } from '../components/modal/modal.component';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { HttpService } from '../services/http.service';
import { Camera, CameraResultType } from '@capacitor/camera';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { api_path } from 'src/environments/environment';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.page.html',
  styleUrls: ['./new-request.page.scss'],
})
export class NewRequestPage implements OnInit {

  @ViewChild(LoadingComponent) loadingComponent;
  @ViewChild(ModalComponent) modalComponent;

  user;
  service;
  type = 'add';
  api_path = api_path

  skills_mapping = []
  selected_skill = ""

  title = "";
  description = "";
  service_description = "";
  price = 0;

  request_submitted = false;
  request_date_index = 0;

  constructor(
    private alertController: AlertController,
    private httpService: HttpService,
    private modalController: ModalController,
    public util: UtilService,
    public userService: UserService,
    public navCtrl: NavController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.userService.getUserData().then(res => {
      this.user = JSON.parse(res.value);
      if (this.user == null) {
        this.router.navigate(['/login']);
        return;
      }
      // console.log("this.user");
      // console.log(this.user);
      // console.log("this.service");
      // console.log(this.service);

      this.userService.getSkillsData().then(res => {
        var skills_categories_mapping = JSON.parse(res.value);
        // this.skills_mapping = Object.keys(skills_categories_mapping).map((key) => skills_categories_mapping[key]);
        this.skills_mapping = Object.keys(skills_categories_mapping).map((key) => skills_categories_mapping[key]);
      });
    })
  }

  handleSkillChange(ev) {
    this.selected_skill = ""+ev.target.value;
    // console.log("this.selected_skill")
    // console.log(this.selected_skill)
  }

  setActiveDateIndex(i) {
    this.request_date_index = i;
  }

  closeRequest() {
    if (this.service) {
      this.modalController.dismiss('service');
    } else { // request
      this.modalController.dismiss('update');
    }
  }

  async presentConfirmRequest() {

    var msg = "تأكيد إضافة الطلب ؟"

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'تأكيد ؟',
      message: msg,
      buttons: [
        {
          text: 'انتظر',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'نعم',
          id: 'confirm-button',
          handler: () => {

            if (this.service) { // request from service
              // console.log("this.service")
              // console.log(this.service)
              this.createRequestFromService();
            } else { // new request
              var input_items = this.getInputItems();
              var invalid_items = this.validateBeforeAdd(input_items);
              invalid_items.items = [ ...new Set(invalid_items.items) ];
              if (invalid_items.status == true) {
                this.createRequest(input_items);
              } else {
                this.presentIncompleteRequest(invalid_items.items)
              }
            }
            
          }
        }
      ]
    });

    await alert.present();
  }

  getInputItems() {
    var input_items = {
      title: this.title,
      description: this.description,
      price: this.price,
      days: 1
    };

    switch (this.request_date_index) {
      case 0:
        input_items.days = 7;
        break;
      case 1:
        input_items.days = 30;
        break;
      case 2:
        input_items.days = 60;
        break;
      default:
        input_items.days = 7;
        break;
    }

    console.log("input_items")
    console.log(input_items)
    return input_items;
  }

  validateBeforeAdd(input_items) {

    var invalid_items = {
      status: true,
      items: [],
    };

    if (input_items.title == '') {
      // alert('يجب ادخال عنوان للطلب');
      invalid_items.status = false;
      invalid_items.items.push('يجب ادخال عنوان للطلب');
    }
    if (input_items.description == '') {
      // alert('يجب ادخال شرح للطلب');
      invalid_items.status = false;
      invalid_items.items.push('يجب ادخال شرح للطلب');
    }

    return invalid_items;
  }

  createRequest(input_items) {

    this.loadingComponent.show('جاري اضافة الطلب ...');

    // add days to todays date
    var due_date = new Date();
    due_date.setDate(due_date.getDate() + input_items.days);
    // format date as %Y-%m-%d %H:%M:%S
    var due_date_str = due_date.toISOString().slice(0, 19).replace('T', ' ');

    const body = {
        "title": input_items.title,
        "description": input_items.description,
        "due_date": due_date_str,
        "skill_id": '1',
        "budget": "0",
        "samples": [],
        "colors": [],
        "other_type": ""
    }

    var url = `request/createRequestOther`;

    this.httpService.doPost(url, body, this.user.jwt).then((res) => {
      if (res.status == 200) {
        // this.modalController.dismiss();
        this.request_submitted = true;
      } else if (res.status == 401) {
        this.util.goToHome(this.user.type);
      }
    })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        this.loadingComponent.hide();
      })

    
  }

  createRequestFromService() {

    this.loadingComponent.show('جاري اضافة الطلب ...');

    let service_id = this.service.Services.id;
    let package_id = this.service.ServicePackages[0].id;
    let title = this.service.Services.title;
    let description = this.service_description;

    const body = {
      "service_id": service_id,
      "package_id": package_id,
      "title": title,
      "description": description
    }

    var url = `v1/services/createRequestForService`;

    this.httpService.doPost(url, body, this.user.jwt).then((res) => {
      if (res.status == 200) {
        // this.modalController.dismiss();
        this.request_submitted = true;
      } else if (res.status == 401) {
        this.util.goToHome(this.user.type);
      }
    })
      .catch(err => {
        console.log(err);
      }).finally(() => {
        this.loadingComponent.hide();
      })

    
  }

  async presentIncompleteRequest(items) {

    var msg = ``

    msg += `
      <ul class='invalid_list'>
    `
    items.forEach(item => {
      msg += `<li>${item}</li>`
    });

    msg += `
      </ul>
    `

    const alert = await this.alertController.create({
      cssClass: 'incomplete-profile',
      header: 'معلومات الطلب غير مكتملة',
      message: msg,
      buttons: []
    });

    await alert.present();
  }

}
