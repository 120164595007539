import { Component, OnInit } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.3s ease-in-out', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        animate('0.3s ease-in-out', style({ opacity: '0' }))
      ])
    ]),
    trigger('slide', [
      transition(':enter', [
        style(
          { opacity: '0', width: '0px', height: '0px' }
        ),
        animate('0.3s ease-in-out', style(
          { opacity: '1', width: '32px', height: '32px' }
        )),
      ]),
      transition(':leave', [
        animate('0.3s ease-in-out', style(
          { opacity: '0', width: '0px', height: '0px' }
        ))
      ])
    ]),
  ]
})
export class LoadingComponent implements OnInit {

  isLoading;
  spinnerShown;
  loadingModalText = "";

  constructor() { }

  ngOnInit() {}

  show(msg) {
    this.isLoading = true;
    setTimeout(() => {
      this.spinnerShown = true;
    }, 200);
    this.loadingModalText = msg;
  }
  hide() {
    setTimeout(() => {
      this.spinnerShown = false;
      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    }, 500);
  }

}
