import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'login',
    // redirectTo: 'soon',
    redirectTo: 'landing',
    pathMatch: 'full'
  },
  {
    path: 'invite/:invite_request_id',
    loadChildren: () => import('./designer/designer.module').then( m => m.DesignerPageModule)
  },
  {
    path: 'Go/:referral_code',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login/guest/:guest',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login/:user_type',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'profile/:designer_id',
    loadChildren: () => import('./client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'request',
    loadChildren: () => import('./request/request.module').then( m => m.RequestPageModule)
  },
  {
    path: 'home-designer',
    loadChildren: () => import('./home-designer/home-designer.module').then( m => m.HomeDesignerPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/:id',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/new_request/:request_type',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/new_request/:request_type/:request_desc',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/chat/:chat_request_id',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'request-details',
    loadChildren: () => import('./request-details/request-details.module').then( m => m.RequestDetailsPageModule)
  },
  {
    path: 'designer',
    loadChildren: () => import('./designer/designer.module').then( m => m.DesignerPageModule)
  },
  {
    path: 'designer/:id',
    loadChildren: () => import('./designer/designer.module').then( m => m.DesignerPageModule)
  },
  {
    path: 'designer/request/:request_id',
    loadChildren: () => import('./designer/designer.module').then( m => m.DesignerPageModule)
  },
  {
    path: 'notif',
    loadChildren: () => import('./notif/notif.module').then( m => m.NotifPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'settings-details',
    loadChildren: () => import('./settings-details/settings-details.module').then( m => m.SettingsDetailsPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'image-view',
    loadChildren: () => import('./image-view/image-view.module').then( m => m.ImageViewPageModule)
  },
  {
    path: 'add-service',
    loadChildren: () => import('./add-service/add-service.module').then( m => m.AddServicePageModule)
  },
  {
    path: 'service',
    loadChildren: () => import('./service/service.module').then( m => m.ServicePageModule)
  },
  {
    path: 'service/:service_id',
    loadChildren: () => import('./service/service.module').then( m => m.ServicePageModule)
  },
  {
    path: 'service-details',
    loadChildren: () => import('./service-details/service-details.module').then( m => m.ServiceDetailsPageModule)
  },
  {
    path: 'services-explorer',
    loadChildren: () => import('./services-explorer/services-explorer.module').then( m => m.ServicesExplorerPageModule)
  },
  {
    path: 'convert-chat',
    loadChildren: () => import('./convert-chat/convert-chat.module').then( m => m.ConvertChatPageModule)
  },
  {
    path: 'desboarding',
    loadChildren: () => import('./desboarding/desboarding.module').then( m => m.DesboardingPageModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'me/:designer_id',
    loadChildren: () => import('./designer-info/designer-info.module').then( m => m.DesignerInfoPageModule)
  },
  {
    path: 'me/service/:service_id',
    loadChildren: () => import('./client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'client/chat/:chat_request_id',
    loadChildren: () => import('./client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'designer-info',
    loadChildren: () => import('./designer-info/designer-info.module').then( m => m.DesignerInfoPageModule)
  },
  {
    path: 'rating',
    loadChildren: () => import('./rating/rating.module').then( m => m.RatingPageModule)
  },
  {
    path: 'designs',
    loadChildren: () => import('./designs/designs.module').then( m => m.DesignsPageModule)
  },
  {
    path: 'actions-modal',
    loadChildren: () => import('./actions-modal/actions-modal.module').then( m => m.ActionsModalPageModule)
  },
  {
    path: 'bank-details',
    loadChildren: () => import('./bank-details/bank-details.module').then( m => m.BankDetailsPageModule)
  },
  {
    path: 'rate',
    loadChildren: () => import('./rate/rate.module').then( m => m.RatePageModule)
  },
  {
    path: 'new-request',
    loadChildren: () => import('./new-request/new-request.module').then( m => m.NewRequestPageModule)
  },
  {
    path: 'login-modal',
    loadChildren: () => import('./login-modal/login-modal.module').then( m => m.LoginModalPageModule)
  },
  {
    path: 'add-account',
    loadChildren: () => import('./add-account/add-account.module').then( m => m.AddAccountPageModule)
  },
  {
    path: 'designers',
    loadChildren: () => import('./designers/designers.module').then( m => m.DesignersPageModule)
  },
  {
    path: 'browser',
    loadChildren: () => import('./browser/browser.module').then( m => m.BrowserPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog/blog.module').then( m => m.BlogPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./landing/terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'howto',
    loadChildren: () => import('./landing/howto/howto.module').then( m => m.HowtoPageModule)
  },
  {
    path: 'qa',
    loadChildren: () => import('./landing/qa/qa.module').then( m => m.QaPageModule)
  },
  {
    path: 'soon',
    loadChildren: () => import('./soon/soon.module').then( m => m.SoonPageModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./blog/content/content.module').then( m => m.ContentPageModule)
  },
  {
    path: 'landing-designer',
    loadChildren: () => import('./landing/landing-designer/landing-designer.module').then( m => m.LandingDesignerPageModule)
  },
  {
    path: 'landing-affiliate',
    loadChildren: () => import('./landing/landing-affiliate/landing-affiliate.module').then( m => m.LandingAffiliatePageModule)
  },
  {
    path: 'hire',
    loadChildren: () => import('./hire/hire.module').then( m => m.HirePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
