import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private navCtrl: NavController
  ) { }
  
  getUserData() {
    // navigate to soon page
    // this.navCtrl.navigateForward('/soon');
    return Preferences.get({ key: 'userData' });
  }

  saveUserData(userData) {
    return Preferences.set({ key: 'userData', value: JSON.stringify(userData) });
  }

  getRequestData() {
    return Preferences.get({ key: 'requestData' });
  }

  saveRequestData(requestData) {
    return Preferences.set({ key: 'requestData', value: JSON.stringify(requestData) });
  }

  getServiceData() {
    return Preferences.get({ key: 'serviceData' });
  }

  saveServiceData(serviceData) {
    return Preferences.set({ key: 'serviceData', value: JSON.stringify(serviceData) });
  }

  saveSkillsData(skillsData) {
    return Preferences.set({ key: 'skillsData', value: JSON.stringify(skillsData) });
  }

  getSkillsData() {
    return Preferences.get({ key: 'skillsData' });
  }

  saveSubSkillsData(subskillsData) {
    return Preferences.set({ key: 'subskillsData', value: JSON.stringify(subskillsData) });
  }

  getSubSkillsData() {
    return Preferences.get({ key: 'subskillsData' });
  }

  clearStorageData() {
    return Preferences.clear();
  }

  convertStringToDict(string_data) {
    if (string_data != "") {
      string_data = JSON.parse(string_data);
    } else {
      string_data = [];
    }
    return string_data;
  }
}
