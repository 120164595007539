import { Component, OnInit } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.4s ease-in-out', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        animate('0.4s ease-in-out', style({ opacity: '0' }))
      ])
    ]),
    trigger('slide', [
      transition(':enter', [
        style(
          { transform: 'translateY(-50px)', opacity: '0', width: '0%' }
        ),
        animate('0.2s ease-in-out', style(
          { transform: 'translateY(0px)', opacity: '1', width: '80%' }
        )),
      ]),
      transition(':leave', [
        animate('0.2s ease-in-out', style(
          { transform: 'translateY(-50px)', opacity: '0', width: '0%' }
        ))
      ])
    ]),
  ]
})
export class ModalComponent implements OnInit {

  shown;
  contentShown;
  content;

  constructor() { }

  ngOnInit() {}

  show(msg) {
    this.content = msg;
    this.shown = true;
    setTimeout(() => {
      this.contentShown = true;
    }, 200);
  }

  hide() {
    this.contentShown = false;
    setTimeout(() => {
      this.shown = false;
    }, 200);
  }

}
