import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { HttpService } from '../services/http.service';
import { LoadingComponent } from '../components/loading/loading.component';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.page.html',
  styleUrls: ['./image-view.page.scss'],
})
export class ImageViewPage implements OnInit {

  @Input('image_urls') image_urls = [];
  @Input('image_url') image_url;
  @Input('image_index') image_index;
  @Input('download') download;

  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private httpService: HttpService,
  ) { }

  ngOnInit() {

    console.log(this.image_index)
    // this.image_url = this.image_url.replace('thumb_','');

    // get image width
    var img = new Image();

    if (this.image_urls.length > 0) {
      img.src = this.image_urls[0];
    } else {
      img.src = this.image_url;
    }

    img.onload = () => {
      var padding = (window.innerWidth - img.width)*1.8;
      document.getElementById('images_wrapper').style.paddingRight = padding+'px';

      setTimeout(() => {
        if (this.image_index != 0) {
          // console.log('this.image_index minus')
          // console.log(-(img.width*this.image_index))
          document.getElementById('images_wrapper').scrollBy({
            left: -(img.width*this.image_index),
            top: 0,
            behavior: 'smooth'
          });
        }
        // document.getElementById("images_wrapper").scrollLeft -= 1000;
      }, 500);
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  downloadURI() {
    var link = document.createElement("a");
    link.download = "dzy_"+Date.now();
    link.href = this.image_url.replace('thumb_','');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
