import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
FirebaseAnalytics.initializeFirebase({
  apiKey: "AIzaSyCkCi8tLj5ZNwVA2GmQKrxg5V3M1ePLNRU",
  authDomain: "dzyni-452ff.firebaseapp.com",
  databaseURL: "https://dzyni-452ff-default-rtdb.firebaseio.com",
  projectId: "dzyni-452ff",
  storageBucket: "dzyni-452ff.appspot.com",
  messagingSenderId: "219358168789",
  appId: "1:219358168789:web:32308145eb541189d1045c",
  measurementId: "G-MMYGEGCXE9"
});

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
    // GooglePlus,
    SignInWithApple,
    // OneSignal,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
