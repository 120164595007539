<ion-content [ngClass]="util.isIOS() ? 'safe_area' : '' ">

  <div style="display: flex; justify-content: space-between; align-items: center;">

    <!-- <div class="back_button" (click)="back()">
      <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1L15 7L9 13" stroke="black" stroke-width="2"/><line x1="15" y1="6.5" y2="6.5" stroke="black" stroke-width="2"/></svg>
    </div> -->

    <!-- <div style="text-align: left;">
      <div class="action_button" (click)="presentConfirmService()">تأكيد إضافة الخدمة</div>
    </div> -->

  </div>

  <h1 *ngIf="type == 'add'">إضافة خدمة</h1>
  <h1 *ngIf="type == 'edit'">تعديل خدمة</h1>

  <div *ngIf="type == 'add'">
    <p class="title">نوع الخدمة</p>

    <div class="service_box_wrapper">
      <div class="service_box"
           (click)="setServiceIndex(i)"
           *ngFor="let st of services_templates; let i = index"
           [ngClass]="i == selected_service_index ? 'active_service_box' : '' ">
           <img class="service_icon" src="{{ st['icon'] }}" alt="">
           <p>{{ st['title'] }}</p>
      </div>
    </div>
    <!-- <ion-list style="background: transparent;">
      <ion-item style="--background: transparent;">
        <ion-select
            [value]="selected_service_index"
            placeholder="اختر خدمة"
            (ionChange)="handleServiceChange($event)"
            [interfaceOptions]="{cssClass: 'my-select'}"
            [multiple]="false">
          <ion-select-option *ngFor="let st of services_templates; let i = index" [value]="i">{{ st['title'] }}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list> -->
  </div>

  <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">العنوان</p>
  <input type="text" tabindex="1" class="service_input" [(ngModel)]="title" required [disabled]="is_input_disabled" >

  <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">الشرح</p>
  <textarea rows="6" tabindex="2" class="service_input" [(ngModel)]="description" required [disabled]="is_input_disabled"></textarea>

  <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">التسليمات</p>
  <div class="service_input_wrapper" *ngFor="let d of deliverables; let i = index">
    <input type="text" tabindex="3" class="service_input" [value]="d" id="deliverable-{{i}}" required [disabled]="is_input_disabled">
    <svg *ngIf="i != (deliverables.length - 1) && i < deliverables.length" (click)="removeServiceDeliverable(i)" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.27148 12.0001C7.27148 11.6438 7.56031 11.355 7.91659 11.355H17.9166C18.2729 11.355 18.5617 11.6438 18.5617 12.0001C18.5617 12.3564 18.2729 12.6452 17.9166 12.6452H7.91659C7.56031 12.6452 7.27148 12.3564 7.27148 12.0001Z" fill="#FCFCFD"/></svg>
    <svg *ngIf="i == (deliverables.length - 1)" (click)="addServiceDeliverable()" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.5945 7.00009C13.5945 6.64381 13.3057 6.35498 12.9494 6.35498C12.5931 6.35498 12.3043 6.64381 12.3043 7.00009V11.355H7.9498C7.59351 11.355 7.30469 11.6439 7.30469 12.0002C7.30469 12.3564 7.59351 12.6453 7.9498 12.6453H12.3043V17.0001C12.3043 17.3564 12.5931 17.6452 12.9494 17.6452C13.3057 17.6452 13.5945 17.3564 13.5945 17.0001V12.6453H17.9498C18.3061 12.6453 18.5949 12.3564 18.5949 12.0002C18.5949 11.6439 18.3061 11.355 17.9498 11.355H13.5945V7.00009Z" fill="#FCFCFD"/></svg>
  </div>

  <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">مدة التسليم</p>
  <div class="service_submit_date_wrapper">
    <div [ngClass]="service_date_index == 0 ? 'active' : '' " (click)="setActiveDateIndex(0)" >اسبوع</div>
    <div [ngClass]="service_date_index == 1 ? 'active' : '' " (click)="setActiveDateIndex(1)" >شهر</div>
    <div [ngClass]="service_date_index == 2 ? 'active' : '' " (click)="setActiveDateIndex(2)" >مخصص</div>
  </div>

  <div id="custom-days-div">
    <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">مدة التسليم (مخصص)</p>
    <input type="number" class="service_input" placeholder="ادخل عدد الأيام" id="custom-days-input">
  </div>

  <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">سعر الخدمة</p>
  <input type="number" tabindex="4" class="service_input" [(ngModel)]="price" (input)="onPriceChange()" required [disabled]="is_input_disabled">

  <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">الدفعات</p>
  <div class="service_input">
    <ion-range dir="ltr"
      aria-label="Custom range"
      [min]="0"
      [max]="100"
      [value]="current_range_value"
      [pin]="true"
      [ticks]="true"
      [snaps]="true"
      [step]="25"
      (ionChange)="onIonChange($event)"
      [disabled]="is_input_disabled"
    ></ion-range>

    <div class="flexy spaced" style="padding: 20px;">
      <div>
        <p class="batch_title">الدفعة الاولى</p>
        <p class="batch_amount"><span id="batch1">0</span> ر.س</p>
      </div>
      <div>
        <p class="batch_title">الدفعة الثانية</p>
        <p class="batch_amount second"><span id="batch2">0</span> ر.س</p>
      </div>
    </div>
  </div>

  <!-- <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">الدفعات بالنسب (ادخل رقم فقط بدون ٪)</p>
  <div class="service_input_wrapper" *ngFor="let b of batches; let i = index">
    <p style="white-space: nowrap;">دفعة {{i+1}}</p>
    <input type="number" tabindex="5" class="service_input" id="batch-{{i}}" placeholder="مثال: 10 = 10% او 100 = 100%" required [disabled]="is_input_disabled">
    <svg *ngIf="i != (batches.length - 1) && i < batches.length" (click)="removeBatchInput(i)" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.27148 12.0001C7.27148 11.6438 7.56031 11.355 7.91659 11.355H17.9166C18.2729 11.355 18.5617 11.6438 18.5617 12.0001C18.5617 12.3564 18.2729 12.6452 17.9166 12.6452H7.91659C7.56031 12.6452 7.27148 12.3564 7.27148 12.0001Z" fill="#FCFCFD"/></svg>
    <svg *ngIf="i == (batches.length - 1) && batches.length < 3" (click)="addNewBatchInput()" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.5945 7.00009C13.5945 6.64381 13.3057 6.35498 12.9494 6.35498C12.5931 6.35498 12.3043 6.64381 12.3043 7.00009V11.355H7.9498C7.59351 11.355 7.30469 11.6439 7.30469 12.0002C7.30469 12.3564 7.59351 12.6453 7.9498 12.6453H12.3043V17.0001C12.3043 17.3564 12.5931 17.6452 12.9494 17.6452C13.3057 17.6452 13.5945 17.3564 13.5945 17.0001V12.6453H17.9498C18.3061 12.6453 18.5949 12.3564 18.5949 12.0002C18.5949 11.6439 18.3061 11.355 17.9498 11.355H13.5945V7.00009Z" fill="#FCFCFD"/></svg>
  </div> -->


  <!-- <p class="title">المجال</p>
  <ion-list style="background: transparent; max-width: 300px;">
    <ion-item style="--background: transparent;">
      <ion-select
        [value]="selected_skill"
        placeholder="اختر مجال"
        (ionChange)="handleSkillChange($event)"
        [multiple]="false">
        <ion-select-option *ngFor="let skill of skills_mapping" [value]="skill['id']">{{ skill['display'] }}</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list> -->


  

  <!-- <p class="title">الباقات</p>
  <ion-row>
    <ion-col size="4" *ngFor="let p of packages; let i = index">
      <div class="package_item">

        <p class="package_title">التسليمات</p>
        <p class="package_subtitle">اكتب كل تسليم في سطر</p>
        <textarea class="package_description" id="package_description_{{i}}" id="pkg-description-{{i+1}}" placeholder="ميزات الباقة"></textarea>

        <p class="package_title">مدة التسليم (عدد الأيام)</p>
        <p class="package_subtitle">من لحظة قبول الطلب، سوف تستغرق حتى تسلم للعميل التسليمات المذكورة</p>
        <input class="package_description" type="number" id="pkg-days-{{i+1}}" placeholder="مدة التسليم">

        <p>السعر</p>
        <input class="package_price" type="number" id="pkg-price-{{i+1}}" placeholder="سعر الباقة" />

        <div *ngIf="packages.length > 1" class="remove_package_btn">
          <svg (click)="removePackage(i)" style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg" class="ionicon rm_design_img" viewBox="0 0 512 512"><title>Trash</title><path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 112h352"/><path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
        </div>

      </div>
    </ion-col>
    <ion-col size="4" *ngIf="packages.length < 3" class="action_button invert" style="width: 100%; margin: 0;" (click)="addNewPackageRow()">+ أضف باقة جديد</ion-col>
  </ion-row> -->


  <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''">نماذج من اعمالك للخدمة</p>
  <div class="service_imgs_wrapper">

    <!-- type add -->
    <div *ngIf="type == 'add'" style="display: inline-block; margin-left: 4px;">
      <div class="service_img" *ngFor="let service_img of service_images_urls"
        [ngStyle]="{ 'background': 'url('+service_img+') center', 'background-size': 'cover' }"
      ></div>
    </div>

    <!-- type edit -->
    <div *ngIf="type == 'edit'" style="display: inline-block; margin-left: 4px;">
      <div class="service_img" *ngFor="let service_img of service?.ServiceImages"
        [ngStyle]="{ 'background': 'url('+api_path+'/'+service_img.File.thumb_url+') center', 'background-size': 'cover' }"
      ></div>
    </div>

    <div class="service_img" *ngFor="let service_img of edit_service_images_urls"
      [ngStyle]="{ 'background': 'url('+service_img+') center', 'background-size': 'cover' }"
    ></div>
    <div *ngIf="service_images_urls.length <= 5" class="service_img" id="add_img_btn" (click)="promptAddImageToService()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C10.0478 4 8.24816 4.161 6.80371 4.35177C5.50372 4.52346 4.52346 5.50372 4.35177 6.80371C4.16101 8.24816 4 10.0478 4 12C4 13.9522 4.161 15.7518 4.35177 17.1963C4.52346 18.4963 5.50372 19.4765 6.80371 19.6482C8.24816 19.839 10.0478 20 12 20C13.9522 20 15.7518 19.839 17.1963 19.6482C18.4963 19.4765 19.4765 18.4963 19.6482 17.1963C19.839 15.7518 20 13.9522 20 12C20 10.0478 19.839 8.24816 19.6482 6.80371C19.4765 5.50372 18.4963 4.52346 17.1963 4.35177C15.7518 4.16101 13.9522 4 12 4ZM6.54184 2.36899C4.34504 2.65912 2.65912 4.34504 2.36899 6.54184C2.16953 8.05208 2 9.94127 2 12C2 14.0587 2.16953 15.9479 2.36899 17.4582C2.65912 19.655 4.34504 21.3409 6.54184 21.631C8.05208 21.8305 9.94127 22 12 22C14.0587 22 15.9479 21.8305 17.4582 21.631C19.655 21.3409 21.3409 19.655 21.631 17.4582C21.8305 15.9479 22 14.0587 22 12C22 9.94127 21.8305 8.05208 21.631 6.54184C21.3409 4.34504 19.655 2.65912 17.4582 2.36899C15.9479 2.16953 14.0587 2 12 2C9.94127 2 8.05208 2.16953 6.54184 2.36899Z" fill="#777E91"/><path d="M9.00061 11C10.1052 11 11.0006 10.1046 11.0006 9C11.0006 7.89543 10.1052 7 9.00061 7C7.89604 7 7.00061 7.89543 7.00061 9C7.00061 10.1046 7.89604 11 9.00061 11Z" fill="#777E91"/><path d="M18.1219 12.7073L19.9162 14.5016C19.8543 15.4378 19.7639 16.3065 19.6643 17.0781L16.7077 14.1215C16.3172 13.731 15.684 13.731 15.2935 14.1215L13.1219 16.2931C11.9504 17.4647 10.0509 17.4647 8.87929 16.2931L8.70771 16.1215C8.31719 15.731 7.68403 15.731 7.2935 16.1215L4.88443 18.5306C4.60536 18.1522 4.41863 17.6981 4.35237 17.1963C4.31623 16.9227 4.28116 16.6363 4.24805 16.3385L5.87929 14.7073C7.05086 13.5357 8.95035 13.5357 10.1219 14.7073L10.2935 14.8789C10.684 15.2694 11.3172 15.2694 11.7077 14.8789L13.8793 12.7073C15.0509 11.5357 16.9504 11.5357 18.1219 12.7073Z" fill="#777E91"/></svg>
    </div>
  </div>


  <!-- <div style="display: flex;justify-content: space-between;align-items: center;">
    <p class="title" [ngClass]="is_input_disabled ? 'disabled' : ''" style="margin: 0;">حالة الخدمة</p>
    <div class="skill_checkbox">
      <ion-toggle [(ngModel)]="service_status" [checked]="isServiceChecked()" color="tertiary"></ion-toggle>
    </div>
  </div> -->



  <div *ngIf="type == 'add'" class="action_button full primary" (click)="presentConfirmService('add')">إضافة</div>
  <div *ngIf="type == 'edit'" class="action_button full primary" (click)="presentConfirmService('edit')">حفظ التغييرات</div>
  <div *ngIf="type == 'edit'" class="action_button full invert" style="color: red; border: none" (click)="presentConfirmService('delete')">حذف الخدمة</div>

  <div style="height: 300px"></div>

</ion-content>

<loading></loading>
<modal></modal>