export const environment = {
  production: true
};

export const api_path = "https://dzyniserver.com:8443";
// export const api_path = "http://167.172.133.112:8443";
// export const api_path = "https://2914-141-179-55-150.ngrok-free.app";
// export const api_path = "https://dzyniserver.com:8443";

export const PAYMENT_MODE = 'Live';
export const PAYMENT_NAME = 'dzyni';
export const PAYMENT_API = 'https://api.noonpayments.com/payment/v1';
export const PAYMENT_KEY = 'e666b7cc2aa24b728b4eea3f9f51a980';