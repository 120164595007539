import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { api_path } from 'src/environments/environment';
import { UtilService } from '../services/util.service';
import { HttpService } from '../services/http.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-actions-modal',
  templateUrl: './actions-modal.page.html',
  styleUrls: ['./actions-modal.page.scss'],
  animations: [
    // fade in and out animation
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0, }),
        animate('0.3s 0.3s ease', style({ opacity: 1, })),
      ]),
      transition(':leave', [
        animate('0.3s ease', style({ opacity: 0, }))
      ])
    ])
  ]
})
export class ActionsModalPage implements OnInit {

  api_path = api_path

  @Input('type') type = "";
  @Input('data') data;
  payment_type = "bank";

  receipt_amount = "";
  receipt_details = "";

  request_status = "";
  
  // join as designer inputs
  joiner_name = "";
  joiner_email = "";
  joiner_url = "";

  // hire designer inputs
  requester_email = "";
  requester_msg = "";

  constructor(
    private modalController: ModalController,
    public util: UtilService,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    // console.log(this.type)
    // console.log(this.data)
  }

  editPaymentType() {
    this.payment_type = "";
  }

  setPaymentType(type) {
    this.payment_type = type;
  }

  askForPayment() {

    var p_mappings = {
      "bank": "Bank",
      "stc_pay": "STCPay",
      "urpay": "URPay",
    }

    this.modalController.dismiss(
      { type: 'withdraw',
        payment_type: p_mappings[this.payment_type],
      }
    );
  }

  createReceipt() {
    this.modalController.dismiss({
      type: 'create_receipt',
      amount: this.receipt_amount,
      details: this.receipt_details,
    });
  }

  addJoinAsDesigner() {
    
    if (this.joiner_name == "" || this.joiner_email == "" || this.joiner_url == "") {
      this.util.presentToast("bottom", "الرجاء ملء جميع الحقول");
      return;
    }

    this.request_status = 'loading';
    var url = `user/joinRequest`;
    
    var data = {
      email: this.joiner_email,
      name: this.joiner_name,
      url: this.joiner_url,
    };

    this.httpService.doPost(url, data).then(res => {
      if (res.status == 200) {
        this.request_status = 'success';
      } else {
        this.request_status = 'error';
        this.util.presentToast("bottom", "حدث خطأ ما");
      }
    })
  }

  submitHireRequest() {

    if (this.requester_email == "" || this.requester_msg == "") {
      this.util.presentToast("bottom", "الرجاء ملء جميع الحقول");
      return;
    }

    this.request_status = 'loading';
    var url = `user/wantToHireRequest`;
    
    var data = {
      email: this.requester_email,
      message: this.requester_msg,
      designer_id: this.data.designer_id,
      notifyOnLaunch: true
    };

    this.httpService.doPost(url, data).then(res => {
      if (res.status == 200) {
        this.request_status = 'success';
      } else {
        this.request_status = 'error';
        this.util.presentToast("bottom", "حدث خطأ ما");
      }
    })
  }

}
