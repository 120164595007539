import { Injectable } from '@angular/core';
import { Http, HttpOptions, HttpResponse } from '@capacitor-community/http';
import { Capacitor } from '@capacitor/core';

import axios from 'axios';

import { api_path } from 'src/environments/environment';

var HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  "ngrok-skip-browser-warning": "any"
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  api_path;

  constructor(

  ) {
    this.api_path = api_path;
  }

  getApiPath() {
    return api_path;
  }

  doGet(url, jwt="", headers={}, useApiPath=true) {

    if (Object.keys(headers).length === 0)
      headers = HEADERS;

    if (jwt != "")
      headers["Authorization"] = `Bearer ${jwt}`
    
    if (useApiPath)
      url = `${this.api_path}/${url}`;

    headers["Content-Type"] = `application/json`;
    const options = {
      url: url,
      headers: headers,
    };

    // console.log("doGet", url, jwt, headers, useApiPath);
  
    // return Http.get(options);
    return axios.get(url, { headers: headers });
  };

  doPost(url, data={}, jwt="", headers={}, useApiPath=true) {

    if (Object.keys(headers).length === 0)
      headers = HEADERS;

    if (jwt != "")
      headers["Authorization"] = `Bearer ${jwt}`

    if (useApiPath)
      url = `${this.api_path}/${url}`;

    const options = {
      url: url,
      headers: headers,
      data: data,
    };

    // console.log("doPost", url, data, jwt, headers, useApiPath);
  
    return axios.post(url, data, { headers: headers });
  };

  doPut(url, data={}, jwt="", headers={}, useApiPath=true) {

    if (Object.keys(headers).length === 0)
      headers = HEADERS;

    if (jwt != "")
      headers["Authorization"] = `Bearer ${jwt}`

    if (useApiPath)
      url = `${this.api_path}/${url}`;

    const options = {
      url: `${this.api_path}/${url}`,
      headers: headers,
      data: data,
    };
  
    return axios.put(url, data, { headers: headers });
    // return Http.put(options);
  };

  doPutImage(url, jwt="", data={}, headers={}, useApiPath=true) {

    if (Object.keys(headers).length === 0)
      headers = HEADERS;

    if (jwt != "")
      headers["Authorization"] = `Bearer ${jwt}`

    if (useApiPath)
      url = `${this.api_path}/${url}`;

    const options = {
      url: `${this.api_path}/${url}`,
      headers: headers,
      data: data,
    };
  
    return axios.put(url, data, { headers: headers });
    // return Http.put(options);
  };

  doDelete(url, jwt="", headers={}, useApiPath=true) {

    if (Object.keys(headers).length === 0)
      headers = HEADERS;

    if (jwt != "")
      headers["Authorization"] = `Bearer ${jwt}`

    if (useApiPath)
      url = `${this.api_path}/${url}`;

    const options = {
      url: url,
      headers: HEADERS,
      data: {},
    };
  
    return axios.delete(url, { headers: headers });
    // return Http.del(options);
  };

  uploadFile(url, jwt="", file=null, count='single') {
  
    var headers = HEADERS;

    if (jwt != "")
      headers["Authorization"] = `Bearer ${jwt}`

    // console.log("file");
    // console.log(file);
    // console.log(file.length);

    var body = null;

    // if (this.isMobile()) {
    //   body = {
    //     "image_data": file
    //   }

      headers["Content-Type"] = `multipart/form-data`;
      body = new FormData();

      if (count == 'multiple') {
        for (let i = 0; i < file.length; i++) {
          body.append('files', file[i]); // for uploading multiple
        }
      } else { // single
        body.append('file', file); // for updating profile image
      }

      url = `${this.api_path}/${url}`;
    // const options = {
    //   url: `${this.api_path}/${url}`,
    //   headers: headers,
    //   data: body
    // };

    // console.log("uploadFile", url, jwt, body, count);
    // console.log(body.get('files'));

    return axios.post(
      url,
      body,
      { 
        headers: headers
      }
    );

  };

  uploadFiles(url, jwt="", files=[], type='POST') {

    var headers = HEADERS;

    if (jwt != "")
      headers["Authorization"] = `Bearer ${jwt}`

    headers["Content-Type"] = `multipart/form-data`;

    let body = new FormData();
    for (let i = 0; i < files.length; i++) {
      body.append('files', files[i]);
    }

    url = `${this.api_path}/${url}`;

    const options = {
      url: url,
      headers: headers,
      data: body
    };
  
    return axios.post(
      url,
      body,
      { 
        headers: headers
      }
    );
    // return Http.post(options);
  };

  isIOS() {
    return Capacitor.getPlatform() == 'ios';
  }

  isMobile() {
    return Capacitor.getPlatform() == 'ios' || Capacitor.getPlatform() == 'android';
  }
}
